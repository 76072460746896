import React, { useCallback, useMemo } from "react";
import { flatten, partial, head, find } from "lodash";
import { Scoped, k, always } from "kremling";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";

export const DocumentNavigator = ({
  documents,
  selectedYear,
  selectedSubDoc,
  navigateToYear,
  navigateToSubDoc,
  isCrmHeirarchy,
}) => {
  const years = documents.map((document) => ({
    year: document[0].year,
    hasChanges: !!document[0].has_changes,
  }));
  const selectedYearInt = useMemo(
    () => parseInt(selectedYear, 10),
    [selectedYear]
  );
  const normalizeElementId = useCallback((elementId) => {
    return elementId.trim().replace(/\s/g, "_");
  }, []);

  if (!find(years, (y) => y.year === selectedYear)) {
    navigateToYear(head(years).year);
  }

  return (
    <Scoped css={css}>
      <div
        className="document-scrollbar"
        style={{ top: isCrmHeirarchy ? "21.5rem" : "14.5rem" }}
      >
        {years.map((y) => (
          <div key={`${y.year}`} className="document-scroll">
            <div
              className={always(
                "nav-doc-years document-scroll document-scroll-section"
              )
                .m(
                  "active-doc-scroll",
                  selectedYearInt === parseInt(y.year, 10)
                )
                .m("has-changes", y.hasChanges)}
              onClick={() => navigateToYear(y.year)}
              offset="160"
            >
              {y.hasChanges && (
                <CpIcon
                  name="shape-dot-filled"
                  size={32}
                  fill="var(--cp-color-app-link-text)"
                  style={{ marginRight: "-8px" }}
                />
              )}
              {y.year}
            </div>

            {selectedYearInt === parseInt(y.year, 10) && (
              <div className="document-list">
                {flatten(documents)
                  .filter(
                    (document) =>
                      parseInt(document.year, 10) === selectedYearInt
                  )
                  .map((subDoc) => (
                    <div
                      key={`${y.year}-${subDoc.id}`}
                      className="document-scroll"
                    >
                      <div
                        className="document-scroll document-scroll-subsection cps-caption"
                        style={
                          selectedSubDoc ===
                          `${normalizeElementId(
                            `subdoc-${subDoc.year}-${subDoc.subtype}`
                          )}`
                            ? {
                                fontWeight: "600",
                              }
                            : {}
                        }
                        onClick={partial(
                          navigateToSubDoc,
                          `${normalizeElementId(
                            `subdoc-${subDoc.year}-${subDoc.subtype}`
                          )}`
                        )}
                        offset="160"
                      >
                        <CpTooltip
                          delay={500}
                          disabled={subDoc.subtype?.length <= 10}
                          text={subDoc.navigation_title}
                        >
                          <div
                            className={always("sub-doc-type").m(
                              "has-changes",
                              subDoc.has_changes
                            )}
                          >
                            {subDoc.has_changes && (
                              <CpIcon
                                name="shape-dot-filled"
                                size={32}
                                fill="var(--cp-color-app-link-text)"
                                style={{ marginRight: "-8px" }}
                              />
                            )}
                            {subDoc.navigation_title}
                          </div>
                        </CpTooltip>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </Scoped>
  );
};

const css = k`
  .document-scrollbar {
    position: fixed;
    width: 105px;
    padding-left: 8px;
    overflow-y: auto;
    height: calc(100vh - 12.5rem);
    top: 14.5rem;
    padding-bottom: 16px;
  }

  .nav-doc-years {
    padding-left: 16px !important;
  }

  .document-list {
    list-style-type: none;
    padding-left: 22px;
  }

  .document-scroll .document-scroll.\+active,
  .document-scroll-section.\+active {
    color: var(--cp-color-button-icon-active-text);
    font-weight: 800;
    transition: border 0.3s ease;
  }

  .document-scroll-section,
  .document-scroll-subsection {
    padding: 0 8px 4px 0;
    min-height: 30px;
    width: 100%;
    vertical-align: middle;
    color: var(--cp-color-app-primary-text);
    cursor: pointer;
  }

  .document-scroll-subsection {
    width: 85px;
    margin: 4px 0px;
    max-height: 40px;
    padding-right: 4px;
    white-space: nowrap;
  }

  .sub-doc-type {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .active-doc-scroll {
    color: var(--cp-color-button-icon-active-text);
    font-weight: 800;
  }

  .has-changes {
    margin-left: -24px;
  }
`;
