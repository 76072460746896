import { isEmpty } from "lodash";
import { pluck } from "rxjs/operators";
import { fetcher, fetchAsObservable } from "fetcher!sofe";

// Transcripts request calls
export const queueTranscriptPull = (body) => {
  return fetchAsObservable(`/api/transcripts`, {
    method: "POST",
    body: JSON.stringify(body),
  }).pipe(pluck("client_ids_to_transcript_ids"));
};

export const queueTranscriptBulkRepull = (body) => {
  return fetchAsObservable(`/api/transcripts/bulk`, {
    method: "POST",
    body,
  });
};

export const deleteTranscripts = (client_id) => {
  return fetchAsObservable(`/api/transcripts`, {
    method: "DELETE",
    body: JSON.stringify({
      client_id,
    }),
  });
};

export const undoTranscriptsDelete = (client_id) => {
  return fetchAsObservable(`/api/transcripts/un-delete`, {
    method: "POST",
    body: JSON.stringify({ client_id }),
  });
};

// SETTINGS
export const getSettings = (
  clientId,
  isBusiness,
  checkTranscripts = false,
  isNewCrm,
  contactId
) => {
  return fetchAsObservable(
    `/api/transcripts/settings/clients/${clientId}?is_business=${isBusiness}&check_transcripts=${checkTranscripts}${
      isNewCrm && contactId ? `&contact_id=${contactId}` : ""
    }`
  );
};

export const updateSettings = (settings, withRequest = false) => {
  return fetchAsObservable(
    `/api/transcripts/settings/${settings.id}?with_request=${withRequest}`,
    {
      method: "PUT",
      body: settings,
    }
  );
};

export const createSettings = (settings, withRequest = false) => {
  return fetchAsObservable(
    `/api/transcripts/settings?with_request=${withRequest}`,
    { method: "POST", body: settings }
  );
};

export const getForms = (isBusiness, isCalendar) => {
  return fetchAsObservable(
    `/api/transcripts/settings/forms?is_business=${!!isBusiness}&is_calendar=${!!isCalendar}`
  );
};

export const getViewMessage = (cliendId) => {
  return fetchAsObservable(
    `/api/transcripts/clients/${cliendId}/view-message`
  ).pipe(pluck("warn_of_settings_change"));
};

export const disableViewMessage = (cliendId) => {
  // this will update view message to false
  return fetchAsObservable(
    `/api/transcripts/clients/${cliendId}/disable-view-message`,
    { method: "PATCH" }
  );
};

// Transcripts data
export const getVersions = (clientId, contactId, isNewCrm) =>
  fetchAsObservable(
    `/api/transcripts/clients/${clientId}${
      isNewCrm && contactId ? `?contact_id=${contactId}` : ""
    }`
  ).pipe(pluck("transcripts"));

export const getVersionDocuments = (versionId) =>
  fetchAsObservable(`/api/transcripts/${versionId}/records`);

export const getDocumentsComparison = (versionId, compareId) =>
  fetchAsObservable(`/api/transcripts/${versionId}/compare/${compareId}`, {
    method: "POST",
  });

export const getReports = (versionId, isBusiness) =>
  fetchAsObservable(
    `/api/transcripts/${versionId}/reports/?is_business=${isBusiness}`
  );

export const requestMessageCenterMessages = () => {
  return fetchAsObservable("/api/transcripts/banners?active=true").pipe(
    pluck("banners")
  );
};

export const getTranscriptsModalDetails = (transcriptId) =>
  fetchAsObservable(`/api/transcripts/${transcriptId}/modal-details`).pipe(
    pluck("details")
  );

export const getFiscalYearBeta = () => {
  return fetchAsObservable(
    "/api/transcripts/irs-beta?feature=fiscal_year"
  ).pipe(pluck("beta"));
};

/*** Save to files ***/
export const getClientFolders = (clientId) =>
  fetchAsObservable(`/api/docs/clients/${clientId}/folders/0/folders`).pipe(
    pluck("folders")
  );

export const createNewFolder = (parentFolderId, folderName, clientId) => {
  const folder = {
    name: folderName,
    parent_folder_id: parentFolderId,
  };
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  return fetchAsObservable(`/api/docs/clients/${clientId}/folders`, {
    method: "POST",
    headers,
    body: `{"folder" : ${JSON.stringify(folder)}}`,
  }).pipe(pluck("files"));
};

export const saveDocumentsToFiles = (versionId, years, types, folderId) => {
  return fetchAsObservable(
    `/api/transcripts/${versionId}/records/send_to_files`,
    {
      method: "POST",
      body: JSON.stringify({ years, types, file_ref: folderId }),
    }
  );
};

export const saveReportsToFiles = (versionId, types, folderId, isBusiness) => {
  return fetchAsObservable(
    `/api/transcripts/${versionId}/reports/send_to_files?is_business=${!!isBusiness}`,
    {
      method: "POST",
      body: JSON.stringify({ types, file_ref: folderId }),
    }
  );
};

/*** Global Dashboard ***/
export const getFilterOptions = (filterType) => {
  return fetchAsObservable(
    `/api/transcripts/dashboard/filter_options/${filterType}`
  ).pipe(pluck("options"));
};

export const getScheduleFilterOptions = (filterType) => {
  return fetchAsObservable(
    `/api/transcripts/scheduled/filter_options/${filterType}`
  ).pipe(pluck("options"));
};

export const getTranscriptsList = (limit, offset, params) => {
  const { sortData, filters, isNewClient = false } = params;
  let addendum = "";

  if (sortData?.sortColumn && sortData.sortDirection) {
    addendum += `&sort=${encodeURIComponent(sortData.sortDirection)}${
      sortData.sortColumn
    }`;
  }
  if (!isEmpty(filters)) {
    addendum += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  if (isNewClient) {
    addendum += "&cached=false";
  }

  return fetcher(
    `/api/transcripts/dashboard?limit=${limit}&offset=${offset}${addendum}`
  );
};

export const getScheduledList = (limit, offset, params) => {
  const { tableSort, filterParams, isNewClient = false } = params;
  let addendum = "";

  if (tableSort?.sortColumn && tableSort.sortDirection) {
    addendum += `&sort=${encodeURIComponent(tableSort.sortDirection)}${
      tableSort.sortColumn
    }`;
  }
  if (!isEmpty(filterParams)) {
    addendum += `&filters=${encodeURIComponent(JSON.stringify(filterParams))}`;
  }

  if (isNewClient) {
    addendum += "&cached=false";
  }

  return fetchAsObservable(
    `/api/transcripts/scheduled?limit=${limit}&offset=${offset}${addendum}`
  );
};

export function getPastDueCount() {
  return fetchAsObservable(`/api/transcripts/scheduled/past-due`).pipe(
    pluck("count")
  );
}

export function getIrsOrganizations() {
  return fetchAsObservable(`/api/transcripts/irs-tokens`).pipe(pluck("tokens"));
}

export function patchUserPreferences(userId, transcriptPrefs) {
  return fetchAsObservable(`/wg/users/${userId}`, {
    method: "PATCH",
    body: {
      users: {
        preferences: {
          transcripts: transcriptPrefs,
        },
      },
    },
  }).pipe(pluck("users"));
}

// FREE TRIAL
export function getSuccessfulTranscriptsCount() {
  return fetchAsObservable(`/api/transcripts/trial-count`);
}

export function getNoticesCount() {
  return fetchAsObservable(`/api/notices/trial-count`);
}

// IRS AUTH
export const sendIrsAuthCode = (code, is_prod) => {
  return fetchAsObservable(`/api/transcripts/irs-authorization-code`, {
    method: "POST",
    body: JSON.stringify({ "authorization-code": code, is_prod }),
  });
};

export const createTestOrganization = (name) => {
  return fetchAsObservable(`/api/transcripts/fake-irs-authorization`, {
    method: "POST",
    body: { name },
  });
};

export const updateOrgToken = (tokenId) => {
  return fetchAsObservable(`/api/transcripts/update-fake-token/${tokenId}`, {
    method: "POST",
  });
};

export const deleteOrgToken = (tokenId) => {
  return fetchAsObservable(`/api/transcripts/irs-tokens/${tokenId}`, {
    method: "DELETE",
  });
};

export const getIrsAuthUrl = () => {
  return fetcher("/api/transcripts/irs_url").then((res) => res.url);
};
