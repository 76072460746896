import React, { useEffect, useState, useRef } from "react";
import { forkJoin } from "rxjs";
import { filter as rxFilter, pluck, first } from "rxjs/operators";
import { Scoped, k } from "kremling";
import { NavContent } from "primary-navbar!sofe";
import CpClientAuth, {
  useWithUserAndTenant,
  useHasAccess,
} from "cp-client-auth!sofe";
import { onPusher } from "fetcher!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import DashboardNavbar from "./dashboard-navbar.component";
import { MessageBanners } from "../common/message-banner/message-banner.component";
import { TranscriptsListWrapper } from "./transcripts-list-wrapper.component";
import { TranscriptsScheduledListWrapper } from "./transcripts-scheduled-list-wrapper.component";
import WelcomeOverlay from "./welcome-overlay.component";
import TranscriptRequestDialog from "../e-services/transcripts-request-dialog.component";
import TNFreeTrialUpgradeBanner from "../common/tn-upgrade-banner.component";
import TranscriptsStatusDetailsModal from "src/common/transcripts-status-details-modal.component";
import { getActiveOrgs } from "src/common/irs-status.component";
import {
  patchUserPreferences,
  getIrsOrganizations,
  getSuccessfulTranscriptsCount,
  getNoticesCount,
  getPastDueCount,
} from "src/resources/transcripts.resource";
import { isTranscriptsOnly } from "../e-services/common/settings.helper";
import { transcriptStatusTypes } from "../common/transcripts-status.helper";
import {
  TranscriptsContext,
  useTranscriptsData,
} from "./use-transcripts-data.hook";
import {
  ScheduledTranscriptsContext,
  useScheduledTranscriptsData,
} from "./use-scheduled-transcripts-data.hook";
import { handleError } from "src/error";

export const tabs = {
  all: "ALL",
  scheduled: "SCHEDULED",
};

export const GeneralDashboard = () => {
  const [activeTab, setActiveTab] = useState(tabs.all);

  const [organizations, setOrganizations] = useState([]);
  const [transcriptsCount, setTranscriptsCount] = useState(null);
  const [noticesCount, setNoticesCount] = useState(null);
  const [pastDueCount, setPastDueCount] = useState(0);
  const [requestDialogClientId, setRequestDialogClientId] = useState("");
  const [settingsModalIds, setSettingsModalIds] = useState({
    clientId: "",
    contactId: "",
  });
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showWelcomeOverlay, setShowWelcomeOverlay] = useState(false);

  const hasTranscriptsPull = useHasAccess("transcripts_pull");
  const hasUnlimitedPulls = useHasAccess("transcripts_pull_unlimited");

  const [user, tenant] = useWithUserAndTenant();

  const transcriptsNoticesCountSub = useRef(null);
  const irsOrgSub = useRef(null);
  const pastDueCountSub = useRef(null);

  const activeOrgs = getActiveOrgs(organizations);

  const isCrmHeirarchy =
    featureEnabled("ft_crm") && tenant?.crm_status === "crm_hierarchy_complete";

  const transcriptsData = useTranscriptsData(
    activeTab,
    activeOrgs,
    transcriptsCount,
    isCrmHeirarchy,
    setShowRequestDialog,
    setShowErrorDialog,
    setSettingsModalIds
  );
  const scheduledTranscriptsData = useScheduledTranscriptsData(activeTab);

  useEffect(() => {
    // unsubscribe on unmount
    return () => {
      transcriptsNoticesCountSub.current?.unsubscribe();
      irsOrgSub.current?.unsubscribe();
      pastDueCountSub.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getOrgsSub = forkJoin([
      CpClientAuth.getLoggedInUserAsObservable().pipe(first()),
      getIrsOrganizations(),
      getPastDueCount(),
    ]).subscribe(([user, organizations, pastDueCount = 0]) => {
      const validOrgs = organizations.filter((org) => !org.deleted_at);
      setPastDueCount(pastDueCount);
      setOrganizations(validOrgs);

      if (hasTranscriptsPull) {
        if (shouldShowWelcomeOverlay(user)) {
          setShowWelcomeOverlay(true);
          patchUserPreferences(user.id, {
            hide_welcome_overlay: true,
          }).subscribe((user) => {
            CpClientAuth.updateLoggedInUserObservable(user);
          }, handleError);
        }
      }
    }, handleError);

    return () => {
      getOrgsSub.unsubscribe();
    };
  }, [hasTranscriptsPull]);

  useEffect(() => {
    const ispLoginPusherHandlerSub = onPusher(
      "transcripts-isp-login"
    ).subscribe(() => {
      fetchOrganizations();
    }, handleError);

    return () => {
      ispLoginPusherHandlerSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const transcriptsPusherHandlerSub = onPusher("transcripts")
      .pipe(
        rxFilter((msg) => msg.data_needed === "status_change"),
        pluck("details")
      )
      .subscribe((details) => {
        if (
          !hasUnlimitedPulls &&
          details.category === transcriptStatusTypes.SUCCESS
        ) {
          fetchTranscriptsNoticesCount();
        }

        if (details.category === transcriptStatusTypes.SUCCESS) {
          fetchPastDueCount();
        }
      }, handleError);

    return () => {
      transcriptsPusherHandlerSub.unsubscribe();
    };
  }, [hasUnlimitedPulls]);

  useEffect(() => {
    if (user && hasUnlimitedPulls === false) {
      fetchTranscriptsNoticesCount();
    }
  }, [user, hasUnlimitedPulls]);

  const noticesLimitReached =
    noticesCount?.successful_count >= noticesCount?.limit;
  const transcriptsLimitReached =
    transcriptsCount?.successful_count >= transcriptsCount?.limit;

  const fetchTranscriptsNoticesCount = () => {
    transcriptsNoticesCountSub.current = forkJoin([
      getSuccessfulTranscriptsCount(),
      getNoticesCount(),
    ]).subscribe(([transcriptsCount, noticesCount]) => {
      const noticesLimitReached =
        noticesCount?.successful_count >= noticesCount?.limit;
      const transcriptsLimitReached =
        transcriptsCount?.successful_count >= transcriptsCount?.limit;

      if (noticesLimitReached || transcriptsLimitReached) {
        window.dispatchEvent(
          new CustomEvent("cp:tn-trial-limits-reached", {
            detail: {
              notices: noticesLimitReached,
              transcripts: transcriptsLimitReached,
            },
          })
        );
      }

      setNoticesCount(noticesCount);
      setTranscriptsCount(transcriptsCount);
    }, handleError);
  };

  const fetchOrganizations = () => {
    irsOrgSub.current = getIrsOrganizations().subscribe((organizations) => {
      const validOrgs = organizations.filter((org) => !org.deleted_at);
      setOrganizations(validOrgs);
    }, handleError);
  };

  const fetchPastDueCount = () => {
    pastDueCountSub.current = getPastDueCount().subscribe(
      setPastDueCount,
      handleError
    );
  };

  const updateOrganization = (updatedOrg) => {
    const newOrganizations = organizations.map((org) => {
      if (org.id === updatedOrg.id) {
        return updatedOrg;
      } else {
        return org;
      }
    });
    setOrganizations(newOrganizations);
  };

  const shouldShowWelcomeOverlay = (user) => {
    return (
      isTranscriptsOnly(user) &&
      !!user.preferences?.transcripts?.hide_welcome_overlay === false
    );
  };

  const deselectAllItems = () => {
    transcriptsData.actions.deselectAllItems();
    scheduledTranscriptsData.actions.deselectAllItems();
  };

  const items =
    activeTab === tabs.all
      ? transcriptsData.allItems
      : scheduledTranscriptsData.scheduledItems;

  const selectedItems =
    activeTab === tabs.all
      ? transcriptsData.selectedItems
      : scheduledTranscriptsData.selectedItems;

  return (
    <NavContent
      hasTopnavSecondary={true}
      style={{ marginLeft: "8rem", padding: "0px" }} // {/* not inline style; props to external component */}
    >
      <Scoped css={css}>
        <TranscriptsContext.Provider value={transcriptsData}>
          <ScheduledTranscriptsContext.Provider
            value={scheduledTranscriptsData}
          >
            <DashboardNavbar
              hasTranscriptsPull={hasTranscriptsPull}
              bulkRequestCb={(itemsPulled) => {
                transcriptsData.actions.setItemsToPending(itemsPulled);
                deselectAllItems();
              }}
              allItems={items}
              selectedItems={selectedItems}
              setShowRequestDialog={(showRequestDialog) =>
                setShowRequestDialog(showRequestDialog)
              }
              transcriptsCount={transcriptsCount}
              organizations={organizations}
              pastDueCount={pastDueCount}
              updateOrganization={updateOrganization}
              fetchOrganizations={fetchOrganizations}
              activeTab={activeTab}
              setActiveTab={(activeTab) => {
                setActiveTab(activeTab);
                deselectAllItems();
              }}
              isNewCrm={isCrmHeirarchy}
            />

            <WelcomeOverlay
              show={showWelcomeOverlay}
              onClose={() => this.setState({ showWelcomeOverlay: false })}
            />

            <div
              style={{
                marginTop: "11.6rem",
              }}
            >
              <MessageBanners />

              {!hasUnlimitedPulls &&
                (noticesLimitReached || transcriptsLimitReached) && (
                  <TNFreeTrialUpgradeBanner
                    transcriptsCount={transcriptsCount}
                    noticesCount={noticesCount}
                    noticesLimitReached={noticesLimitReached}
                    transcriptsLimitReached={transcriptsLimitReached}
                  />
                )}
              <div>
                {activeTab !== tabs.scheduled && (
                  <TranscriptsListWrapper
                    transcriptsCount={transcriptsCount}
                    activeOrgs={activeOrgs}
                    setShowRequestDialog={setShowRequestDialog}
                    setRequestDialogClientId={setRequestDialogClientId}
                    settingsModalIds={settingsModalIds}
                    setSettingsModalIds={setSettingsModalIds}
                    isCrmHeirarchy={isCrmHeirarchy}
                  />
                )}
                {activeTab === tabs.scheduled && (
                  <TranscriptsScheduledListWrapper
                    transcriptsCount={transcriptsCount}
                    pastDueCount={pastDueCount}
                    activeOrgs={activeOrgs}
                    setShowRequestDialog={setShowRequestDialog}
                    setRequestDialogClientId={setRequestDialogClientId}
                    settingsModalIds={settingsModalIds}
                    setSettingsModalIds={setSettingsModalIds}
                    isCrmHeirarchy={isCrmHeirarchy}
                  />
                )}
              </div>
            </div>

            <TranscriptRequestDialog
              show={showRequestDialog}
              onClose={(clientId) => {
                if (clientId) {
                  transcriptsData.actions.setPendingNewClients([
                    ...transcriptsData.pendingNewClients,
                    clientId,
                  ]);
                }
                setShowRequestDialog(false);
                setRequestDialogClientId("");
              }}
              clientId={requestDialogClientId}
              transcriptsCount={transcriptsCount}
              fetchOrganizations={fetchOrganizations}
            />

            <TranscriptsStatusDetailsModal
              show={!!showErrorDialog}
              close={() => setShowErrorDialog(false)}
              transcriptsId={showErrorDialog.transcript_id}
              statusObj={{
                category: showErrorDialog.category,
                id: showErrorDialog.transcript_id,
                status: showErrorDialog.status,
              }}
            />
          </ScheduledTranscriptsContext.Provider>
        </TranscriptsContext.Provider>
      </Scoped>
    </NavContent>
  );
};

const css = k`
  .loader-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    min-height: 90px;
  }
`;
