import React from "react";
import { HashRouter, Switch } from "react-router-dom";
import { GeneralDashboard } from "./dashboard/general-dashboard.component";
import ClientTranscripts from "./client-transcripts/client-transcripts.component";
import { HelpCenter } from "./help-center/help-center.component";
import IRSAuthorized from "./e-services/irs-authorized.component";
import PrivateRoute from "./common/private-route.component";

export default function Root() {
  return (
    <HashRouter>
      <Switch>
        <PrivateRoute
          path="/transcripts/list"
          render={(props) => <GeneralDashboard {...props} />}
          permissions="transcripts"
        />
        <PrivateRoute
          path="/client/:clientId/transcripts/reports"
          render={(props) => (
            <ClientTranscripts {...props} selectedView="reports" />
          )}
          permissions="transcripts"
        />
        <PrivateRoute
          path="/client/:clientId/transcripts/documents"
          render={(props) => (
            <ClientTranscripts {...props} selectedView="documents" />
          )}
          permissions="transcripts"
        />
        <PrivateRoute
          path="/transcripts/help-center"
          render={() => <HelpCenter topic="caf-error" />}
          permissions="transcripts"
        />
        <PrivateRoute
          path="/transcripts/help-center/tin-name-error"
          render={() => <HelpCenter topic="tin-name-error" />}
          permissions="transcripts"
        />
        <PrivateRoute
          path="/transcripts/irs-authorized"
          render={(props) => <IRSAuthorized {...props} />}
          permissions="transcripts"
        />
      </Switch>
    </HashRouter>
  );
}
