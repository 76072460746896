import React from "react";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { transcriptStatusTypes } from "../table.helper";
import { queueTranscriptBulkRepull } from "src/resources/transcripts.resource";
import { notifyAnalytics } from "src/resources/analytics.resource";
import { handleError } from "src/error";

export const ToolsCell = ({
  resource,
  activeOrgs,
  isScheduledList,
  isCrmHeirarchy,
  userHasTranscriptPullPerm,
  setShowRequestDialog,
  transcriptsCount,
  showSettingsModal,
}) => {
  const pendingStatus = resource?.category === transcriptStatusTypes.PENDING;
  const orgIsActive = activeOrgs?.length
    ? !!activeOrgs.find((org) => org.id === resource.irs_token_id)
    : false;

  const queueUpTranscriptPull = ({
    clientId,
    contactId,
    missingSettings,
    setShowRequestDialog,
    orgIsActive,
    isCrmHeirarchy,
  }) => {
    if (missingSettings || !orgIsActive) {
      setShowRequestDialog(true, clientId);
    } else {
      const body = isCrmHeirarchy
        ? {
            client_and_contact_map: [
              { client_id: clientId, contact_id: contactId },
            ],
          }
        : { client_ids: [clientId] };
      return queueTranscriptBulkRepull(body).subscribe(() => {
        notifyAnalytics("transcripts.requested_again");
        successToast("Your transcripts request has been submitted.");
      }, handleError);
    }
  };

  return (
    <div onClick={(e) => e.preventDefault()}>
      {!isScheduledList && userHasTranscriptPullPerm && (
        <CpTooltip text="Request again">
          <span>
            <CpButton
              icon="af-refresh"
              aria-label="Request transcripts again"
              onClick={() =>
                queueUpTranscriptPull({
                  clientId: resource.client_id,
                  contactId: resource.contact_id,
                  missingSettings:
                    !resource.has_settings || resource.add_missing_details,
                  setShowRequestDialog,
                  orgIsActive,
                  isCrmHeirarchy,
                })
              }
              disabled={
                pendingStatus ||
                transcriptsCount?.successful_count >= transcriptsCount?.limit
              }
            />
          </span>
        </CpTooltip>
      )}
      <CpButton
        icon="misc-gear"
        aria-label="Transcripts request settings"
        onClick={() =>
          showSettingsModal(resource.client_id, resource.contact_id)
        }
      />
    </div>
  );
};
