import React from "react";
import { Scoped, k } from "kremling";
import { CpCheckbox, CpRadio } from "canopy-styleguide!sofe";
import { getAvailableYears } from "../documents/documents.helper";

export default function DocumentsSelector({
  documentTypes,
  allYears,
  setAllYears,
  selectedYears,
  setSelectedYears,
  allDocs,
  setAllDocs,
  selectedDocs,
  setSelectedDocuments,
}) {
  const removeDoc = (doc) =>
    setSelectedDocuments(selectedDocs.filter((set) => set !== doc));
  const addDoc = (doc) => setSelectedDocuments([...selectedDocs, doc]);
  const removeYear = (year) =>
    setSelectedYears(selectedYears.filter((set) => set !== year));
  const addYear = (year) => setSelectedYears([...selectedYears, year]);

  const years = getAvailableYears(
    allYears,
    allDocs,
    selectedDocs,
    documentTypes
  );

  return (
    <Scoped css={css}>
      <div className="stf-subheader">
        Select the document(s) you would like to save
      </div>
      <div className="documents-years-selection">
        <CpRadio
          onChange={(value) => setAllDocs(value === "all-docs")}
          value={allDocs ? "all-docs" : "individual-docs"}
          name="all-documents"
        >
          <CpRadio.Item id="all-docs">All documents</CpRadio.Item>
          <CpRadio.Item id="individual-docs">
            Select individual documents
          </CpRadio.Item>
        </CpRadio>
      </div>
      {!allDocs && (
        <div className="individual-documents">
          {documentTypes.map((document) => {
            if (!document.label || !document.availableYears) return false;

            const checked = selectedDocs.includes(document.value);

            return (
              <div key={document.value} className="document-option">
                <CpCheckbox
                  checked={checked}
                  onChange={(checked) => {
                    if (checked) {
                      addDoc(document.value);
                    } else {
                      removeDoc(document.value);
                    }
                  }}
                >
                  {document.label}
                </CpCheckbox>
              </div>
            );
          })}
        </div>
      )}
      <div className="stf-subheader">
        Select the year(s) you would like to save
      </div>
      <div className="documents-years-selection">
        <CpRadio
          onChange={(value) => setAllYears(value === "all-years")}
          value={allYears ? "all-years" : "individual-years"}
          name="all-years"
        >
          <CpRadio.Item id="all-years">All years</CpRadio.Item>
          <CpRadio.Item id="individual-years">
            Select individual years
          </CpRadio.Item>
        </CpRadio>
      </div>
      {!allYears && (
        <div
          className="individual-years"
          style={{ height: `${(years.length / 2) * 40}px` }}
        >
          {years.map((year) => {
            if (!year) return;
            const checked = selectedYears.includes(year.toString());

            return (
              <div key={year} className="year-option">
                <CpCheckbox
                  checked={checked}
                  onChange={(checked) => {
                    if (checked) {
                      addYear(year.toString());
                    } else {
                      removeYear(year.toString());
                    }
                  }}
                >
                  {year}
                </CpCheckbox>
              </div>
            );
          })}
        </div>
      )}
    </Scoped>
  );
}

const css = k`
  .stf-header {
    font-size: 24px;
    padding: 16px 16px 0px;
  }

  .stf-subheader {
    font-size: 18px;
    font-weight: normal;
  }

  .documents-years-selection {
    padding: 16px 0;
    margin-left: 8px;
  }

  .individual-documents {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px 40px 8px;
  }

  .document-option {
    margin-bottom: 8px;
  }

  .years-selection {
    padding-top: 16px;
    margin-left: 8px;
  }

  .individual-years {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 256px;
    padding: 0px 40px 8px;
    min-height: 100px;
  }

  .year-option {
    max-width: 60px;
    margin-bottom: 8px;
  }
`;
