import React from "react";
import { useCss, k, a } from "kremling";
import { CpEmptyState, CpCard } from "canopy-styleguide!sofe";

export default function TranscriptsListEmptyState({
  emptyWithFilter,
  isSchedule,
  userHasTranscriptPullPerm,
}) {
  const scoped = useCss(css);
  let subText = isSchedule
    ? "You currently have no scheduled transcript requests to show."
    : "Use your IRS e-services account and your power of attorney to get started.";

  if (emptyWithFilter) {
    subText =
      "There are no requests that meet your filter selections. Please refine your filters or request a new transcript. ";
  } else if (!isSchedule && !userHasTranscriptPullPerm) {
    subText = "Account permission needed to pull transcripts from the IRS.";
  }

  return (
    <div className="cp-pt-16">
      <CpCard
        {...scoped}
        className={a("dashboard-empty-state").m("cp-mt-16", emptyWithFilter)}
      >
        <CpEmptyState
          img={isSchedule ? "es_calendar_month" : "es_transcripts_list"}
          text={isSchedule ? "No Scheduled Requests" : "No Transcript Requests"}
          subText={subText}
        />
      </CpCard>
    </div>
  );
}

const css = k`
  .dashboard-empty-state {
    margin: 1.6rem 1.6rem;
    height: 40rem;
    display: flex;
    align-items: center;
  }
`;
