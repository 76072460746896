import React, { useContext, useMemo } from "react";
import { isEmpty, find } from "lodash";
import { featureEnabled } from "feature-toggles!sofe";
import TranscriptsListHeader from "src/dashboard/transcripts-list-header.component";
import TranscriptsListRow from "src/dashboard/transcripts-list-row.component";
import TranscriptsListEmptyState from "src/dashboard/transcripts-list-empty-state.component";
import { TranscriptsTable } from "./table/transcripts-table.component";
import { TranscriptsContext } from "./use-transcripts-data.hook";

export default function TranscriptsList({
  showSettingsModal,
  setStatusObj,
  setShowRequestDialog,
  transcriptsCount,
  activeOrgs,
  isCrmHeirarchy,
}) {
  const {
    allItems,
    selectedItems,
    allSelected,
    sortData,
    filters,
    actions,
    permissions,
  } = useContext(TranscriptsContext);

  const filterActive = useMemo(
    () => !!find(filters, (filterProp) => !isEmpty(filterProp)),
    [filters]
  );

  return allItems.length || filterActive ? (
    <>
      {featureEnabled("toggle_transcripts_table") ? (
        <TranscriptsTable />
      ) : (
        <>
          <TranscriptsListHeader
            updateFilterParams={actions.updateFilterParams}
            filterParams={filters}
            sortOptions={sortData}
            selectAllItems={actions.selectAllItems}
            deselectAllItems={actions.deselectAllItems}
            allSelected={allSelected}
            userHasTranscriptPullPerm={permissions.canPullTranscripts}
            isCrmHeirarchy={isCrmHeirarchy}
          />
          {!allItems.length && filterActive ? (
            <TranscriptsListEmptyState
              emptyWithFilter
              userHasTranscriptPullPerm={permissions.canPullTranscripts}
            />
          ) : (
            <div className="cp-mb-24">
              {allItems.map((item) => (
                <TranscriptsListRow
                  key={item.transcript_id}
                  showSettingsModal={showSettingsModal}
                  item={item}
                  selected={selectedItems.includes(item.transcript_id)}
                  selectItem={actions.selectItem}
                  deselectItem={actions.deselectItem}
                  userHasTranscriptPullPerm={permissions.canPullTranscripts}
                  setStatusObj={setStatusObj}
                  setShowRequestDialog={setShowRequestDialog}
                  transcriptsCount={transcriptsCount}
                  activeOrgs={activeOrgs}
                  isCrmHeirarchy={isCrmHeirarchy}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  ) : (
    <TranscriptsListEmptyState
      userHasTranscriptPullPerm={permissions.canPullTranscripts}
    />
  );
}
