import React, { useState, useEffect } from "react";
import { useCss, k } from "kremling";
import { DateTime } from "luxon";
import {
  CpModal,
  CpButton,
  CpCheckbox,
  cdnImage,
} from "canopy-styleguide!sofe";
import { routeToIrs } from "src/common/irs-status.component";

export default function IrsRequirements({
  show,
  close,
  fetchOrganizations,
  orgsSaved,
}) {
  const scoped = useCss(css);
  const [hideIrsRequirements, setHideIrsRequirements] = useState(false);

  useEffect(() => {
    if (show) {
      localStorage.setItem("irs-requirements-seen", DateTime.now());
    }
  }, [show]);

  useEffect(() => {
    if (hideIrsRequirements) {
      localStorage.setItem("hide-irs-requirements", true);
    } else {
      localStorage.removeItem("hide-irs-requirements");
    }
  }, [hideIrsRequirements]);

  return (
    <CpModal show={show} onClose={close} width={520}>
      <div
        {...scoped}
        className="cp-flex-column cp-flex-center cp-p-16 cp-pb-32"
      >
        <CpButton
          className="close-btn"
          icon="close-large"
          aria-label="close"
          onClick={close}
        />
        <h1 className="irs-requirements-title">New IRS Requirements</h1>
        <img src={cdnImage("clock_timer.svg")} />
        <h2 className="irs-requirements-subtitle">
          1-hour IRS sign in sessions
        </h2>
        <ul className="irs-requirement-list">
          <li>Signing into the IRS begins a session that lasts 1 hour.</li>
          <li>You may request transcripts at any time during that session.</li>
          <li>When the allotted time has expired you will be signed out.</li>
          <li>
            If you have more transcripts to request you may sign back in to
            begin a new session.
          </li>
        </ul>
        {orgsSaved ? (
          <CpCheckbox
            className="cp-mt-24"
            onChange={setHideIrsRequirements}
            checked={hideIrsRequirements}
          >
            Don&apos;t show this again
          </CpCheckbox>
        ) : (
          ""
        )}
        <CpButton
          className="cp-mt-24"
          btnType="primary"
          onClick={() => {
            routeToIrs(fetchOrganizations);
            close();
          }}
        >
          Sign in to the IRS
        </CpButton>
        <a
          className="cp-mt-24"
          href="https://support.getcanopy.com/hc/en-us/articles/14871944773659"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
    </CpModal>
  );
}

const css = k`
    .irs-requirements-title {
        font-weight: 600;
        font-size: 2rem;
    }

    .irs-requirements-subtitle {
        font-weight: 600;
        font-size: 1.4rem;
    }

    .irs-requirement-list {
        width: 40.9rem;
        padding-left: 2.8rem;
    }

    .irs-requirement-list li {
        margin: 0.8rem 0;
    }

    .close-btn {
        position: absolute !important;
        top: .8rem;
        right: .8rem;
    }
`;
