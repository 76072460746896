import React, { useState } from "react";
import { Scoped } from "kremling";
import { NavContent } from "primary-navbar!sofe";

import { HelpCenterDashboard } from "./help-center-dashboard.component";
import { Accordion } from "./accordion.component";
import css from "./help-center.krem.css";

export const HelpCenter = ({ topic }) => {
  const [activeAccordion, setActiveAccordion] = useState(topic ? topic : null);

  function handleClick(accordionName) {
    return () => {
      if (isActive(accordionName)) {
        setActiveAccordion(null);
      } else {
        setActiveAccordion(accordionName);
      }
    };
  }

  function isActive(accordionName) {
    return activeAccordion === accordionName;
  }

  return (
    <Scoped css={css}>
      <div>
        <NavContent
          hasTopnavSecondary={true}
          style={{ marginLeft: 80, padding: "0px" }}
        >
          <HelpCenterDashboard />
          <div className="card-content cps-body-sm">
            <div className="card-wrapper">
              <div className="cps-card cp-p-16 cp-mb-32">
                <h4 className="help-card-title">
                  How to use Canopy Transcripts
                </h4>
                <Accordion
                  className="accordion"
                  onClick={handleClick("first-time-user")}
                  open={isActive("first-time-user")}
                  toolbar={<span className="cps-wt-bold">First time user</span>}
                >
                  To access the transcripts tool, users must have an E-Services
                  account. This can be completed through the IRS website at
                  irs.gov/e-services and completing an e-Services application
                  under E-file Provider Services (towards the bottom of page).
                  Processing time frame is expected to be around 45 days.
                </Accordion>

                <Accordion
                  className="accordion"
                  onClick={handleClick("transcript-pull")}
                  open={isActive("transcript-pull")}
                  toolbar={
                    <span className="cps-wt-bold">Transcripts pull</span>
                  }
                >
                  <a href="https://canopytax.zendesk.com/hc/en-us/sections/115000338934-Transcripts">
                    Transcripts pull guide
                  </a>
                </Accordion>
              </div>
            </div>

            <div className="card-wrapper">
              <div className="cps-card cp-p-16 cp-mb-32">
                <h4 className="help-card-title">Error types and causes</h4>
                <Accordion
                  className="accordion"
                  onClick={handleClick("caf-error")}
                  open={isActive("caf-error")}
                  toolbar={<span className="cps-wt-bold">CAF Error</span>}
                >
                  <div className="help-content">
                    Possible reasons for failure:
                    <ul>
                      <li>Incorrect organization selected</li>
                      <li>The POA is not in effect</li>
                      <li>Incorrect CAF number</li>
                      <li>No documents associated</li>
                    </ul>
                  </div>

                  <div className="help-content">
                    Items to consider:
                    <ul>
                      <li>
                        Date POA faxed to CAF unit - (Per IRM 21.3.7.1.4(2)- All
                        receipts will be processed within 5 business days, based
                        upon a first in first out process)
                      </li>
                      <li>
                        Incorrect organization used for request - (Confirm the
                        correct organization was selected as part of the IRS
                        login experience. E-services allows for different
                        options (name, name and business name, and/or business
                        name) that must match the POA)
                      </li>
                      <li>
                        CAF number - (Confirm the CAF number on record with the
                        IRS matches the CAF number listed in Canopy)
                      </li>
                      <li>
                        No documents - (Try requesting the transcripts
                        exclusively through the Transcripts Delivery Service
                        (TDS) to confirm status)
                      </li>
                    </ul>
                  </div>
                </Accordion>
                <Accordion
                  className="accordion"
                  onClick={handleClick("tin-name-error")}
                  open={isActive("tin-name-error")}
                  toolbar={<span className="cps-wt-bold">TIN/Name Error</span>}
                >
                  <div className="help-content">
                    Possible reasons for failure:
                    <ul>
                      <li>
                        The TIN and name control (first 4 letters of TP’s last
                        name) in the IRS system do not match
                      </li>
                      <li>
                        The account has been moved from one TIN to another TIN
                      </li>
                      <li>
                        A transposition error in the TIN or name change has
                        occurred
                      </li>
                    </ul>
                  </div>

                  <div className="help-content">
                    Items to consider:
                    <ul>
                      <li>Client recently changed his/her name</li>
                      <li>TIN was entered incorrectly</li>
                      <li>
                        The taxpayer recently convert from an ITN to a SSN
                      </li>
                    </ul>
                  </div>
                </Accordion>
                <Accordion
                  className="accordion"
                  onClick={handleClick("unauthorized-tds")}
                  open={isActive("unauthorized-tds")}
                  toolbar={
                    <span className="cps-wt-bold">Unauthorized TDS</span>
                  }
                >
                  <div className="help-content">
                    Items to consider:
                    <ul>
                      <li>
                        Confirm e-services account has the Transcript Delivery
                        Service (TDS) turned on
                      </li>
                    </ul>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </NavContent>
      </div>
    </Scoped>
  );
};
