import React, { Component, createRef } from "react";
import { mergeMap, map } from "rxjs/operators";
import Cancelable from "react-disposable-decorator";
import { Scoped, k, always } from "kremling";
import { get, isEmpty } from "lodash";
import {
  CpIcon,
  CpLoader,
  CpButton,
  CpModal,
  keydownEventStack,
  CpInput,
} from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";
import ReportsSelector from "./reports-selector.component";
import DocumentsSelector from "./documents-selector.component";
import {
  getClientFolders,
  createNewFolder,
  saveReportsToFiles,
  saveDocumentsToFiles,
} from "src/resources/transcripts.resource";
import { notifyAnalytics } from "../../resources/analytics.resource";
import { handleError } from "src/error";

const defaultState = {
  allYears: true,
  selectedYears: [],
  allDocs: true,
  selectedDocs: [],
  selectedFolder: null,
  showingCreateFolder: false,
  newFolderName: "",
  saving: false,
};
@Cancelable
@UserTenantProps()
export default class SaveToFilesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folders: [],
      foldersLoaded: false,
      foldersLoadingError: false,
      ...defaultState,
    };

    this.foldersRef = createRef();
  }

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getClientFolders(this.props.client.id).subscribe(
        (folders) => {
          this.setState({ folders, foldersLoaded: true });
        },
        (err) => {
          this.setState({ foldersLoaded: true, foldersLoadingError: true });
          handleError(err);
        }
      )
    );
  }

  componentWillUnmount() {
    if (this.foldersRef?.current) this.foldersRef.current.remove();
  }

  createNewFolder = (event) => {
    event.preventDefault();
    const { selectedFolder, newFolderName } = this.state;
    const parentFolderId = get(selectedFolder, "id", "0");

    if (!newFolderName) return this.setState({ showingCreateFolder: false });

    this.props.cancelWhenUnmounted(
      createNewFolder(
        parentFolderId,
        newFolderName.trim(),
        this.props.client.id
      )
        .pipe(
          mergeMap((newFolder) => {
            return getClientFolders(this.props.client.id).pipe(
              map((result) => {
                return { selectedFolder: newFolder[0], folders: [...result] };
              })
            );
          })
        )
        .subscribe(
          ({ selectedFolder, folders }) => {
            this.setState({
              folders,
              showingCreateFolder: false,
              newFolderName: "",
              selectedFolder,
            });
          },
          (err) => {
            this.setState({ newFolderName: "" });
            handleError(err);
          }
        )
    );
  };

  setSelectedDocs = (selectedDocs) => this.setState({ selectedDocs });

  setAllYears = (allYears) => this.setState({ allYears });

  setAllDocs = (allDocs) => this.setState({ allDocs });

  setSelectedYears = (selectedYears) => this.setState({ selectedYears });

  closeCreateFolder = () => {
    if (this.foldersRef?.current) this.foldersRef.current.remove();
    this.setState({ showingCreateFolder: false, newFolderName: "" });
  };

  showCreateFolder = () => {
    this.setState({ showingCreateFolder: true });
  };

  setSelectedFolder = (selectedFolder) => {
    const { showingCreateFolder } = this.state;

    if (showingCreateFolder) return;
    this.setState({ selectedFolder });
  };

  saveToFiles = () => {
    const { allYears, selectedYears, allDocs, selectedDocs, selectedFolder } =
      this.state;
    const { client } = this.props;
    const types = allDocs ? null : selectedDocs;
    const folderId = selectedFolder ? selectedFolder.id : "0";
    const isCrmV2 =
      featureEnabled("ft_crm") &&
      this.props.tenant.crm_status === "crm_hierarchy_complete";
    const clientName = isCrmV2
      ? client.display_name
      : client.is_business
      ? client.business_name
      : `${client.first_name} ${client.last_name}`;
    const possessiveName =
      clientName.split().filter(Boolean).pop() === "s"
        ? `${clientName}' files`
        : `${clientName}'s files`;
    const folderName = selectedFolder ? selectedFolder.name : possessiveName;
    const folderLocationUrl = `/#/docs/clients/${this.props.client.id}/files${
      selectedFolder ? `?selected_file=${folderId}&no_preview=true` : ""
    }`;

    this.setState({ saving: true });

    if (this.props.type === "documents") {
      const years = allYears ? null : selectedYears;

      notifyAnalytics("transcripts.saved_to_files_docs", {
        client_type: client.is_business ? "business" : "individual",
      });

      this.props.cancelWhenUnmounted(
        saveDocumentsToFiles(
          this.props.versionId,
          years,
          types,
          folderId
        ).subscribe(
          () => {
            this.onClose();
            this.setState({ saving: false });
            successToast({
              message: "Saved in:",
              links: [{ label: folderName, url: folderLocationUrl }],
            });
          },
          (err) => {
            this.onClose();
            this.setState({ saving: false });
            handleError(err);
          }
        )
      );
    } else if (this.props.type === "reports") {
      notifyAnalytics("transcripts.saved_to_files_reports", {
        client_type: client.is_business ? "business" : "individual",
      });

      this.props.cancelWhenUnmounted(
        saveReportsToFiles(
          this.props.versionId,
          types,
          folderId,
          client.is_business
        ).subscribe(
          () => {
            this.onClose();
            this.setState({ saving: false });
            successToast({
              message: "Saved in:",
              links: [{ label: folderName, url: folderLocationUrl }],
            });
          },
          (err) => {
            this.onClose();
            this.setState({ saving: false });
            handleError(err);
          }
        )
      );
    }
  };

  renderFolders = (folders) => {
    const { selectedFolder, showingCreateFolder } = this.state;

    return folders.map((folder) => {
      const isSelected = folder.id === selectedFolder?.id;
      const parentCount = get(folder, "id_path", "0")
        .split("/")
        .filter(Boolean).length;
      const selectedIdPath = get(selectedFolder, "id_path", "0")
        .split("/")
        .filter(Boolean);
      const isOpen = isSelected || selectedIdPath.includes(folder.id);

      return (
        <div key={folder.id}>
          <div
            onClick={() => this.setSelectedFolder(folder)}
            className={always("folder-row")
              .m("fr-clickable", !showingCreateFolder)
              .m("fr-selected", isSelected && !showingCreateFolder)}
            style={{ paddingLeft: `${parentCount * 32}px` }}
          >
            <CpIcon
              name="folder"
              fill="var(--cp-color-app-secondary-text)"
              style={{ marginTop: "-2px" }}
            />
            <div className="folder-name">{folder.name}</div>
          </div>
          {isSelected &&
            showingCreateFolder &&
            this.renderCreateNewFolder(parentCount)}
          {isOpen && folder.has_children && this.renderFolders(folder.folders)}
        </div>
      );
    });
  };

  renderCreateNewFolder = (parentCount = 0) => {
    this.foldersRef.current = keydownEventStack.add({
      keys: ["Escape", "Esc"],
      callback: this.closeCreateFolder,
    });

    return (
      <div
        className="create-folder"
        style={{ paddingLeft: `${(parentCount + 1) * 32}px` }}
      >
        <form
          onSubmit={this.createNewFolder}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <CpIcon
              name="folder"
              fill="var(--cp-color-app-secondary-text)"
              className="cp-mt-4"
            />
            <CpInput
              autoFocus
              className="create-folder-input"
              value={this.state.newFolderName}
              onChange={(value) =>
                this.setState({ newFolderName: value.trimStart() })
              }
              maxLength="60"
            />
            {this.state.newFolderName && (
              <CpButton btnType="flat" onClick={this.createNewFolder}>
                Create folder
              </CpButton>
            )}
          </div>
          <CpButton
            className="close-create-folder"
            icon="close-small"
            aria-label="Close"
            onClick={this.closeCreateFolder}
          />
        </form>
      </div>
    );
  };

  onClose = () => {
    this.setState(defaultState);
    this.props.close();
  };

  render() {
    const {
      allYears,
      selectedYears,
      folders,
      allDocs,
      selectedDocs,
      foldersLoaded,
      foldersLoadingError,
      showingCreateFolder,
      selectedFolder,
    } = this.state;
    const {
      documentTypes,
      type,
      client,
      showSaveToFilesModal,
      reportTypeOptions,
    } = this.props;
    const noFolders = isEmpty(folders);
    const saveBtnDisabled =
      showingCreateFolder ||
      (!allYears && isEmpty(selectedYears)) ||
      (!allDocs && isEmpty(selectedDocs));
    const isCrmV2 =
      featureEnabled("ft_crm") &&
      this.props.tenant.crm_status === "crm_hierarchy_complete";
    const clientName = isCrmV2
      ? client.display_name
      : client.is_business
      ? client.business_name
      : `${client.first_name} ${client.last_name}`;
    const possessiveName =
      clientName.split().filter(Boolean).pop() === "s"
        ? `${clientName}' files`
        : `${clientName}'s files`;
    const folderName = selectedFolder ? selectedFolder.name : possessiveName;
    const noMoreFoldersAllowed =
      get(selectedFolder, "id_path", "0").split("/").filter(Boolean).length > 7;

    return (
      <CpModal show={showSaveToFilesModal} onClose={this.onClose} width={540}>
        <Scoped css={css}>
          <div style={{ maxHeight: "70rem" }}>
            <div className="stf-header">
              <div>Save to Files</div>
              {!noFolders && !showingCreateFolder && !noMoreFoldersAllowed && (
                <CpButton
                  icon="folder-add"
                  onClick={this.showCreateFolder}
                  aria-label="Create folder"
                />
              )}
            </div>
            <div className="stf-modal-body">
              {type === "documents" ? (
                <DocumentsSelector
                  documentTypes={documentTypes}
                  allYears={allYears}
                  setAllYears={this.setAllYears}
                  selectedYears={selectedYears}
                  setSelectedYears={this.setSelectedYears}
                  allDocs={allDocs}
                  setAllDocs={this.setAllDocs}
                  selectedDocs={selectedDocs}
                  setSelectedDocuments={this.setSelectedDocs}
                />
              ) : (
                <ReportsSelector
                  selectedReports={selectedDocs}
                  allReports={allDocs}
                  setAllReports={this.setAllDocs}
                  setSelectedReports={this.setSelectedDocs}
                  reportTypeOptions={reportTypeOptions}
                />
              )}
              {foldersLoaded ? (
                <div>
                  <div className="stf-subheader">
                    Select the client&apos;s folder you would like to save to
                  </div>
                  <div className="canopy-folders-wrapper" ref={this.foldersRef}>
                    {noFolders && !showingCreateFolder ? (
                      foldersLoadingError ? (
                        <div>
                          Client&apos;s folders could not be loaded at this
                          time, please close and try again
                        </div>
                      ) : (
                        <div className="folders-empty">
                          <div style={{ marginBottom: "16px" }}>
                            No folders exist for this client
                          </div>
                          <CpButton
                            className="cp-ml-16"
                            btnType="secondary"
                            icon="folder-add"
                            aria-label="Create folder"
                            onClick={this.showCreateFolder}
                          >
                            Create a folder
                          </CpButton>
                        </div>
                      )
                    ) : (
                      <div className="folder-files-section">
                        <div className="folder-section">
                          {this.renderFolders(folders)}
                        </div>
                        {showingCreateFolder &&
                          !selectedFolder &&
                          this.renderCreateNewFolder()}
                        <div
                          className="blank-folder-row"
                          onClick={() => this.setSelectedFolder(null)}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="cp-pb-8">
                  <CpLoader />
                </div>
              )}
            </div>
            <div className="stf-actions">
              <div className="stf-actions-left">
                <CpButton
                  btnType="primary"
                  onClick={this.saveToFiles}
                  disabled={saveBtnDisabled}
                  showLoader={this.state.saving}
                >
                  Save
                </CpButton>
                <CpButton
                  btnType="flat"
                  onClick={this.onClose}
                  disabled={this.state.saving}
                >
                  Cancel
                </CpButton>
              </div>
              <div
                style={{ marginTop: "6px", display: "flex", maxWidth: "300px" }}
              >
                <div style={{ width: "95px" }}>Save to:</div>
                <div className="stf-actions-folder-name">{folderName}</div>
              </div>
            </div>
          </div>
        </Scoped>
      </CpModal>
    );
  }
}

const css = k`
  .stf-header {
    font-size: 24px;
    padding: 16px 16px 0px;
    display: flex;
    justify-content: space-between;
  }

  .stf-subheader {
    font-size: 18px;
    font-weight: normal;
  }

  .stf-actions {
    padding: 16px 20px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    border-top: solid 1px var(--cp-color-well-border);
  }

  .canopy-folders-wrapper {
    margin: 16px -24px 8px -24px;
  }

  .stf-modal-body {
    max-height: 54rem;
    overflow-y: auto;
    padding: 16px 24px 0;
  }

  .folder-row {
    padding: 4px 8px 4px 32px;
    display: flex;
  }

  .fr-clickable {
    cursor: default;
  }

  .fr-clickable:hover {
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .fr-selected {
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .blank-folder-row {
    padding: 0;
    height: 32px;
  }

  .folders-empty {
    padding-left: 15rem;
    margin-bottom: 32px;
  }

  .create-folder {
    padding: 4px 0px 4px 32px;
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .create-folder-input {
    margin-left: 8px;
    font-size: 13px;
  }

  .create-folder-input:focus {
    outline: none;
  }

  .close-create-folder {
    float: right;
  }

  .submit-create-folder {
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: var(--cp-color-app-primary-text);
    font-weight: normal;
  }

  .submit-create-folder:hover {
    text-decoration: underline;
  }

  .submit-create-folder:focus {
    outline: none;
    text-decoration: underline;
  }

  .folder-name {
    padding-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .stf-actions-folder-name {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
  }
`;
