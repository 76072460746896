import { pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";

export function getClient(clientId) {
  return fetchAsObservable(`/api/clients/${clientId}`).pipe(pluck("clients"));
}

export function getClients(term, limit = "20", isNewCrm = false) {
  return fetchAsObservable(`/api/v2/clients/search`, {
    method: "POST",
    body: {
      search: term,
      sort_data: [],
      limit,
      page: 1,
      filters: {
        is_active: [{ equal_to: true }],
        is_archived: [{ equal_to: false }],
      },
      visible_columns: [
        "full_name",
        "display_name",
        "first_name",
        "last_name",
        "middle_name",
        "ein",
        "ssn",
        ...(isNewCrm ? ["contacts"] : []),
      ],
    },
  }).pipe(pluck("clients"));
}

export function createClient(data) {
  return fetchAsObservable(`/api/clients`, {
    method: "POST",
    body: { clients: data },
  }).pipe(pluck("clients"));
}

export function patchClient(id, data) {
  return fetchAsObservable(
    `/api/clients/${id}?include=users,clients,tags,client_for,client_sources`,
    {
      method: "PATCH",
      body: { clients: data },
    }
  ).pipe(pluck("clients"));
}

export function getContact(contactId) {
  return fetchAsObservable(`/api/v2/contacts/${contactId}`);
}

export function createContact(data) {
  return fetchAsObservable("/api/v2/contacts", {
    method: "POST",
    body: data,
  });
}

export function patchContact(id, data) {
  return fetchAsObservable(`/api/v2/contacts/${id}`, {
    method: "PATCH",
    body: data,
  });
}

// CAF
export function getCafs(userId) {
  return fetchAsObservable(`/wg/users/${userId}/cafs`).pipe(pluck("cafs"));
}

export function createUpdateCafs(cafs, userId) {
  return fetchAsObservable(`/wg/users/${userId}/cafs`, {
    method: "PUT",
    body: JSON.stringify({ cafs }),
  }).pipe(pluck("cafs"));
}
