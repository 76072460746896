import React from "react";
import { useCss, k } from "kremling";
import { CpButton, cdnImage, CpOverlay } from "canopy-styleguide!sofe";

export default function WelcomeOverlay({ show, onClose }) {
  const scope = useCss(css);
  return (
    <CpOverlay show={show} onClose={onClose}>
      <CpOverlay.Body>
        <div className="body-container" {...scope}>
          <div className="body-grid">
            <div className="left-container">
              <img
                src={cdnImage("success_computer.svg")}
                className="success-computer-img"
              />
            </div>
            <div className="right-container">
              <div className="header-text cp-mb-32">{`Welcome to Canopy’s IRS Transcripts
and Notices Module`}</div>
              <div className="description-container">
                <p>
                  {
                    "Start pulling IRS Transcripts today by integrating your IRS account with Canopy. Once you complete the integration, you'll be able to quickly request IRS transcripts for your clients."
                  }
                </p>
                <p>
                  {
                    "If you are using QuickBooks Online to manage your clients, we suggest you also integrate with your QBO account to make pulling transcripts even faster."
                  }
                </p>
              </div>
              <CpButton
                className="cp-mt-24"
                btnType="primary"
                href={`/#/global-settings/integrations`}
                anchor
              >
                {"Integrate with IRS"}
              </CpButton>
            </div>
          </div>
        </div>
      </CpOverlay.Body>
    </CpOverlay>
  );
}

const css = k`
  p {
    color: var(--cp-color-app-secondary-text);
    line-height: 1.5;
    font-size: 1.6rem;
  }

  .header-text {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.3;
  }

  .description-container {
    width: 85%;
  }

  .body-container {
    display: flex;
    height: 100%;
    align-items: center;
    margin: auto;
    max-width: 1000px;
    transform: translatey(-32px);
  }

  .left-container {
    text-align: center;
  }

  .body-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  .success-computer-img {
    width: 350px;
  }
`;
