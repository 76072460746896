import React from "react";
import { Scoped, k } from "kremling";
import { Link } from "react-router-dom";
import { CpCheckbox } from "canopy-styleguide!sofe";
import { getContents } from "src/dashboard/transcripts-list-row.helper.js";

export default function TranscriptsListRow({
  item,
  selected,
  selectItem,
  deselectItem,
  userHasTranscriptPullPerm,
  showSettingsModal,
  setStatusObj,
  setShowRequestDialog,
  transcriptsCount,
  activeOrgs,
  isScheduledList,
  isCrmHeirarchy,
}) {
  const checkboxHandler = (checked, item) => {
    checked ? selectItem(item) : deselectItem(item);
  };

  return (
    <Scoped css={css}>
      <div className="row-wrapper list-row">
        <Link
          to={`/client/${item.client_id}/transcripts/reports${
            isCrmHeirarchy && item.contact_id
              ? `?contact_id=${item.contact_id}`
              : ""
          }`}
          className="white row"
          style={{
            textDecoration: "none",
            color: "var(--cp-color-app-primary-text))",
          }}
        >
          <div
            className="width-1"
            style={{ marginLeft: "32px", display: "flex" }}
            onClick={(e) => e.stopPropagation()}
          >
            <CpCheckbox
              onChange={(checked) => {
                checkboxHandler(checked, item);
              }}
              checked={!!selected}
              disabled={!userHasTranscriptPullPerm}
            />
            {item.pull_method === "SECURE_MAILBOX" && (
              <div className="sor-callout">SOR</div>
            )}
          </div>
          {getColumns(isScheduledList, isCrmHeirarchy).map((cell) => {
            return (
              <div
                key={cell.column}
                className={`${cell.className} padding-bottom cp-color-app-primary-text`}
              >
                {getContents({
                  column: cell.column,
                  item,
                  userHasTranscriptPullPerm,
                  showSettingsModal,
                  setStatusObj,
                  setShowRequestDialog,
                  transcriptsCount,
                  activeOrgs,
                  isScheduledList,
                  isCrmHeirarchy,
                })}
              </div>
            );
          })}
        </Link>
      </div>
    </Scoped>
  );
}

const getColumns = (isScheduledList, isCrmHeirarchy) => {
  return isScheduledList
    ? [
        { className: "width-2", column: "client_id" },
        ...(isCrmHeirarchy
          ? [{ className: "width-2", column: "contact_id" }]
          : []),
        { className: "width-2", column: "cadence" },
        { className: "width-2", column: "next_run_at" },
        { className: "width-2", column: "organization" },
        { className: "width-1", column: "tools" },
      ]
    : [
        { className: "width-2", column: "client_id" },
        ...(isCrmHeirarchy
          ? [{ className: "width-2", column: "contact_id" }]
          : []),
        { className: "width-2", column: "next_run_cadence" },
        { className: "width-2", column: "last_pull_date" },
        { className: "width-2", column: "last_successful_pull_date" },
        { className: "width-3", column: "status" },
        { className: "width-2", column: "poa_expiration_at" },
        { className: "width-1", column: "tools" },
      ];
};

const css = k`
  .row {
    height: calc(100% - 0.1rem);
    display: flex;
    align-items: center;
  }

  .row:hover {
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .row-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 3.2rem;
  }

  .width-3 {
    flex: 3;
    padding-left: 1.6rem;
  }

  .width-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1.6rem 0;
    white-space: nowrap;
  }

  .width-1 {
    flex: 1;
  }

  .white {
    background-color: var(--cp-color-card-bg);
  }

  .list-row {
    font-size: 1.3rem;
  }

  .row-wrapper {
    height: 4rem;
    width: 100%;
    border-bottom: 1px solid var(--cp-color-app-border);
  }

  .tools-column {
    display: flex;
    justify-content: end;
    padding-right: 2.4rem;
  }

  .fake-link-text {
    color: var(--cp-color-app-link-text);
  }

  .fake-link-text:hover {
    text-decoration: underline;
    text-decoration-color: var(--cp-color-app-link-text);
  }

  .sor-callout {
    color: var(--cp-color-app-secondary-text);
    margin-left: 1.6rem;
    font-size: 1.1rem;
    padding-top: 0.2rem;
  }
`;
