import { CpTable } from "canopy-styleguide!sofe";
import { ScheduleCell } from "./cells/schedule-cell.component";
import { DateTimeCell } from "./cells/date-time-cell.component";
import { StatusCell } from "./cells/status-cell.component";
import { POAExpirationCell } from "./cells/poa-expiration-cell.component";
import { ToolsCell } from "./cells/tools-cell.component";
import { getFilterOptions } from "src/resources/transcripts.resource";

export const getTranscriptsListSchema = ({
  activeOrgs,
  canPullTranscripts,
  setShowRequestDialog,
  setShowErrorDialog,
  setSettingsModalIds,
  transcriptsCount,
  isCrmHeirarchy,
}) => ({
  clientId: {
    fieldId: "client_name",
    label: "Client Name",
    dataType: "string",
    cell: {
      component: CpTable.LinkCell,
      props: ({ value, resource }) => ({
        label: value,
        href: `#/client/${resource.client_id}/transcripts/reports`,
      }),
    },
    sort: {
      fieldId: "client_name",
    },
    filter: {
      fieldId: "client_id",
      component: CpTable.SelectFilter,
      props: {
        getSelectionOptions: () => {
          return getFilterOptions("client_id").toPromise();
        },
      },
    },
  },
  ...(isCrmHeirarchy && {
    contactId: {
      fieldId: "contact_name",
      label: "Contact name",
      dataType: "string",
      sort: true,
      filter: {
        fieldId: "contact_id",
        component: CpTable.SelectFilter,
        props: {
          getSelectionOptions: () => {
            return getFilterOptions("contact_id").toPromise();
          },
        },
      },
    },
  }),
  nextRun: {
    fieldId: "next_run_at",
    label: "Schedule",
    dataType: "string",
    cell: { component: ScheduleCell },
    sort: {
      ascLabel: "Oldest - Newest",
      descLabel: "Newest - Oldest",
    },
  },
  lastAttempt: {
    fieldId: "last_pull_date",
    label: "Latest Attempt",
    dataType: "string",
    cell: { component: DateTimeCell },
    sort: {
      ascLabel: "Oldest - Newest",
      descLabel: "Newest - Oldest",
    },
    filter: {
      component: CpTable.DateFilter,
      props: {
        hideNoDateAdded: true,
        dateRanges: [
          "today",
          "yesterday",
          "currentWeek",
          "lastWeek",
          "currentMonth",
          "lastMonth",
          "custom",
        ],
      },
    },
  },
  lastSuccess: {
    fieldId: "last_successful_pull_date",
    label: "Last Successful Pull",
    dataType: "string",
    cell: { component: DateTimeCell },
    sort: {
      ascLabel: "Oldest - Newest",
      descLabel: "Newest - Oldest",
    },
  },
  status: {
    fieldId: "status",
    label: "Status",
    dataType: "string",
    cell: {
      component: StatusCell,
      props: ({ value, resource }) => ({
        value,
        resource,
        setShowErrorDialog,
      }),
    },
    filter: {
      fieldId: "label",
      component: CpTable.SelectFilter,
      props: {
        getSelectionOptions: () => {
          return getFilterOptions("label").toPromise();
        },
      },
    },
  },
  poaExpiration: {
    fieldId: "poa_expiration_at",
    label: "POA Expiration",
    dataType: "string",
    cell: { component: POAExpirationCell },
    sort: {
      ascLabel: "Oldest - Newest",
      descLabel: "Newest - Oldest",
    },
  },
  tools: {
    dataType: "component",
    width: "sm",
    cell: {
      component: ToolsCell,
      props: ({ resource }) => ({
        resource,
        activeOrgs,
        isScheduledList: false,
        isCrmHeirarchy,
        userHasTranscriptPullPerm: canPullTranscripts,
        setShowRequestDialog,
        transcriptsCount,
        showSettingsModal: (settingsModalId, contactId = "") => {
          setSettingsModalIds({
            clientId: settingsModalId,
            contactId,
          });
        },
      }),
    },
  },
});
