import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";

export default function PrivateRoute({
  children,
  render,
  permissions,
  ...rest
}) {
  const [user] = useWithUserAndTenant();
  return user ? (
    <Route
      {...rest}
      render={(props) =>
        hasAccess(user)(permissions) ? (
          // cloning to provide react router props which will be used by children
          render?.(props) || React.cloneElement(children, props)
        ) : (
          <Redirect to="/403" />
        )
      }
    />
  ) : null;
}
