import { useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import { useQuery, genQueryKey } from "src/react-query";
import { getTranscriptsList } from "src/resources/transcripts.resource";

const removeEmpty = (obj) => {
  const newObj = cloneDeep(obj);
  Object.keys(newObj).forEach((key) => {
    if (isEmpty(newObj[key])) {
      delete newObj[key];
    }
  });
  return newObj;
};

export const useTranscriptsQuery = ({
  page,
  limit,
  filters,
  sortData,
  isNewClient = false,
}) => {
  const [pageCount, setPageCount] = useState();
  const [totalTranscriptsCount, setTotalTranscriptsCount] = useState();

  const query = useQuery({
    queryKey: genQueryKey("transcripts", {
      page,
      limit,
      filters,
      sortData,
      isNewClient,
    }),
    queryFn: () =>
      getTranscriptsList(limit, page * limit, {
        sortData,
        filters: removeEmpty(filters),
        isNewClient,
      }).then((response) => {
        setTotalTranscriptsCount(response.total);
        setPageCount(Math.floor(response.total / limit));
        return response.transcripts;
      }),
    staleTime: 60 * 1000, // 1 minute
  });

  return {
    transcripts: query.data,
    transcriptsQuery: query,
    pageCount,
    totalTranscriptsCount,
  };
};
