import React, { createRef, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { k, a, useCss } from "kremling";
import {
  CpIcon,
  CpButton,
  CpTooltip,
  CpCheckbox,
} from "canopy-styleguide!sofe";
import FilterMenu from "./filter-menu.component";
import FilterDatesMenu from "./filter-menu-dates.component";

export default function TranscriptsListHeader({
  filterParams,
  sortOptions,
  updateFilterParams,
  selectAllItems,
  deselectAllItems,
  allSelected,
  isScheduledList,
  userHasTranscriptPullPerm,
  isCrmHeirarchy,
}) {
  const scoped = useCss(css);
  const [filteringColumn, setFilteringColumn] = useState(null);
  const headerRef = createRef();

  const menuClickHandler = (e) => {
    if (filteringColumn && !headerRef?.current?.contains(e.target)) {
      setFilteringColumn(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", menuClickHandler);
    return () => document.removeEventListener("click", menuClickHandler);
  }, [filteringColumn]);

  const closeFilterMenu = () => {
    setFilteringColumn(null);
  };

  const resetSortFilter = (e, column, isDate) => {
    e.stopPropagation();

    const columnFilters = isDate ? {} : [];
    const persistSort = column.sortName !== sortOptions?.sortColumn;
    updateFilterParams(column, null, columnFilters, persistSort);
    setFilteringColumn(null);
  };

  const columnNames = getColumnNames(isScheduledList, isCrmHeirarchy);

  return (
    <div {...scoped}>
      <div className="sticky" style={{ top: "11.6rem" }}>
        <div className="header-row header-row-wrapper white" ref={headerRef}>
          <div className="header-row">
            <div className="header-column column-header cp-ml-32 cp-pb-4 width-1">
              <CpCheckbox
                onChange={allSelected ? deselectAllItems : selectAllItems}
                checked={!!allSelected}
                disabled={!userHasTranscriptPullPerm}
              />
            </div>
            {columnNames.map((column) => {
              const {
                columnType,
                title,
                isDate,
                filterable,
                sortable,
                className,
                sortName,
              } = column;
              const showFilterMenu =
                filteringColumn === columnType && (filterable || sortable);
              const showResetButton =
                sortOptions?.sortColumn === columnType ||
                !isEmpty(filterParams[columnType]);

              return (
                <div
                  key={columnType}
                  className={a(`header-row column-header ${className}`)
                    .m("clickable-header", filterable || sortable)
                    .m("header-clicked", showFilterMenu)}
                  onClick={() => {
                    setFilteringColumn(columnType);
                  }}
                >
                  <div>
                    <span>{title}</span>
                    {(filterable || sortable) && (
                      <CpIcon name="caret-small-down" />
                    )}
                  </div>
                  {showResetButton ? (
                    <CpTooltip text="Clear sort and filter">
                      <CpButton
                        icon="close-circle-filled"
                        aria-label="Clear sort and filter"
                        onClick={(e) => resetSortFilter(e, column, isDate)}
                      />
                    </CpTooltip>
                  ) : (
                    <div />
                  )}
                  {showFilterMenu &&
                    (isDate ? (
                      <FilterDatesMenu
                        updateFilterParams={updateFilterParams}
                        persistingSort={
                          sortOptions?.sortColumn &&
                          sortOptions.sortColumn === sortName
                            ? sortOptions.sortDirection
                            : null
                        }
                        persistingFilter={filterParams[columnType]}
                        close={closeFilterMenu}
                        column={column}
                        isScheduledList={isScheduledList}
                      />
                    ) : (
                      <FilterMenu
                        updateFilterParams={updateFilterParams}
                        persistingSort={
                          sortOptions?.sortColumn &&
                          sortOptions.sortColumn === sortName
                            ? sortOptions.sortDirection
                            : null
                        }
                        persistingFilters={filterParams[columnType]}
                        close={closeFilterMenu}
                        column={column}
                        isScheduledList={isScheduledList}
                      />
                    ))}
                </div>
              );
            })}
            <div className="end width-1" />
          </div>
        </div>
      </div>
    </div>
  );
}

const getColumnNames = (isScheduledList, isCrmHeirarchy) => {
  return isScheduledList
    ? [
        {
          columnType: "client_id",
          title: "Client name",
          className: "width-2",
          isDate: false,
          filterable: true,
          sortable: true,
          sortName: "client_id",
        },
        ...(isCrmHeirarchy
          ? [
              {
                columnType: "contact_id",
                title: "Contact",
                className: "width-2",
                isDate: false,
                filterable: true,
                sortable: true,
                sortName: "contact_id",
              },
            ]
          : []),
        {
          columnType: "cadence",
          title: "Frequency",
          className: "width-2",
          isDate: false,
          filterable: true,
          sortable: true,
          sortName: "cadence",
        },
        {
          columnType: "next_run_at",
          title: "Next send date",
          className: "width-2",
          isDate: true,
          filterable: true,
          sortable: true,
          sortName: "next_run_at",
        },
        {
          columnType: "irs_token_id",
          title: "Organization",
          className: "width-2",
          isDate: false,
          filterable: true,
          sortable: true,
          sortName: "irs_token_id",
        },
      ]
    : [
        {
          columnType: "client_id",
          title: "Client name",
          className: "width-2",
          isDate: false,
          filterable: true,
          sortable: true,
          sortName: "client_name",
        },
        ...(isCrmHeirarchy
          ? [
              {
                columnType: "contact_id",
                title: "Contact",
                className: "width-2",
                isDate: false,
                filterable: true,
                sortable: true,
                sortName: "contact_id",
              },
            ]
          : []),
        {
          columnType: "next_run_cadence",
          title: "Schedule",
          className: "width-2",
          isDate: true,
          filterable: false,
          sortable: true,
          sortName: "next_run_at",
        },
        {
          columnType: "last_pull_date",
          title: "Latest Attempt",
          className: "width-2",
          isDate: true,
          filterable: true,
          sortable: true,
          sortName: "last_pull_date",
        },
        {
          columnType: "last_successful_pull_date",
          title: "Last Successful Pull",
          className: "width-2",
          isDate: true,
          filterable: false,
          sortable: true,
          sortName: "last_successful_pull_date",
        },
        {
          columnType: "label",
          title: "Status",
          className: "width-3",
          isDate: false,
          filterable: true,
          sortable: false,
          sortName: "label",
        },
        {
          columnType: "poa_expiration_at",
          title: "POA Expiration",
          className: "width-2",
          isDate: true,
          filterable: false,
          sortable: true,
          sortName: "poa_expiration_at",
        },
      ];
};

const css = k`
  .column-header {
    cursor: default;
    justify-content: space-between;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .clickable-header:hover {
    background-color: var(--cp-color-menu-hover-bg);
  }

  .clickable {
    cursor: pointer;
  }

  .header-clicked {
    background-color: var(--cp-color-menu-hover-bg);
  }

  .header-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 3.2rem;
  }

  .width-3 {
    flex: 3;
    padding-left: 16px;
  }

  .width-2 {
    flex: 2;
    padding: 0 16px 0;
  }

  .width-1 {
    flex: 1;
  }

  .white {
    background-color: var(--cp-color-card-bg);
  }  

  .header-row {
      font-weight: 600;
      font-size: 13px;
      color: var(--cp-color-app-secondary-text);
      min-height: 50px;
      width: 100%;
      z-index: 1;
      height: calc(100% - 1px);
      display: flex;
    }

    .header-row-wrapper {
      height: 4rem;
      width: 100%;
      border-bottom: 1px solid var(--cp-color-app-border);
    }

    .sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 5.6rem;
      border: 1px solid var(--cp-color-app-border);
      z-index: 50;
    }

    .reset-filters-button {
      margin-left: 80px;
    }
`;
