import React from "react";
import { CpSwitch, CpCase, CpIcon } from "canopy-styleguide!sofe";
import { transcriptStatusTypes } from "../table.helper";

export const StatusCell = ({ value, resource, setShowErrorDialog }) => {
  return (
    <div style={{ marginLeft: "-8px" }}>
      <CpSwitch expression={resource.category}>
        <CpCase value={transcriptStatusTypes.PENDING}>
          <div className="cp-flex-center">
            <CpIcon className="cp-mr-4" name="misc-ellipsis" /> {value}
          </div>
        </CpCase>
        <CpCase value={transcriptStatusTypes.ERROR}>
          <div className="cp-flex-center">
            <CpIcon
              className="cp-mr-4"
              name="close-small"
              fill="var(--cp-color-app-warning-text)"
            />
            <a
              className="cp-color-app-primary-text"
              onClick={() => setShowErrorDialog(resource)}
            >
              {value}
            </a>
          </div>
        </CpCase>
        <CpCase value={transcriptStatusTypes.SUCCESS}>
          <div className="cp-flex-center">
            <CpIcon
              className="cp-mr-4"
              name="checkmark-small"
              fill="var(--cp-color-app-success-text)"
            />
            {value}
          </div>
        </CpCase>
        <CpCase value={transcriptStatusTypes.PARTIAL_SUCCESS}>
          <div className="cp-flex-center">
            <CpIcon
              className="cp-mr-4"
              name="urgent-filled-small"
              fill="var(--cp-color-app-icon)"
            />
            {value}
          </div>
        </CpCase>
        <CpCase value={transcriptStatusTypes.RETRYING}>
          <div className="cp-flex-center">
            <CpIcon
              className="cp-mr-4"
              name="af-retry-small"
              fill="var(--cp-color-app-icon)"
            />
            {value}
          </div>
        </CpCase>
      </CpSwitch>
    </div>
  );
};
