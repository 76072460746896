import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import { CpModal, CpButton, CpLoader } from "canopy-styleguide!sofe";
import { handleError } from "src/error";
import { getTranscriptsModalDetails } from "../resources/transcripts.resource";

export default function TranscriptsStatusDetailsModal({
  show,
  close,
  transcriptsId,
  statusObj,
}) {
  const genericMsg =
    "We could not fulfill this request. Please contact support and provide the Transcripts ID.";
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ description: genericMsg });
  const [expandedList, setExpandedList] = useState(false);
  const additionalItems = expandedList
    ? details.additional_items
    : details.additional_items?.slice(0, 3);

  useEffect(() => {
    if (transcriptsId && show) {
      setLoading(true);
      const sub = getTranscriptsModalDetails(transcriptsId).subscribe(
        (details) => {
          if (!details.description && !details.description_line_2) {
            setDetails({ description: genericMsg });
          } else {
            setDetails(details);
          }
          setLoading(false);
        },
        (err) => {
          setDetails({
            description: genericMsg,
          });
          setLoading(false);
          handleError(err);
        }
      );

      return () => sub.unsubscribe?.();
    }
  }, [transcriptsId, show]);

  const getHtml = (description) =>
    description
      ? sanitizeHtml(description, {
          allowedTags: [
            "h3",
            "h4",
            "h5",
            "h6",
            "blockquote",
            "p",
            "ul",
            "ol",
            "nl",
            "li",
            "b",
            "i",
            "strong",
            "em",
            "strike",
            "hr",
            "br",
            "div",
            "table",
            "thead",
            "caption",
            "tbody",
            "tr",
            "th",
            "td",
          ],
          allowedAttributes: {
            "*": ["border", "height", "width", "align", "name"],
          },
        })
      : "";

  return (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title={statusObj?.status || "Error Details"} />
      {loading ? (
        <CpLoader />
      ) : (
        <CpModal.Body>
          <div
            dangerouslySetInnerHTML={{ __html: getHtml(details.description) }}
          />
          {details.description_line_2 && (
            <div
              className="cp-mt-16"
              dangerouslySetInnerHTML={{
                __html: getHtml(details.description_line_2),
              }}
            />
          )}
          {additionalItems && (
            <div>
              <div style={{ maxHeight: "25rem", overflowY: "auto" }}>
                {additionalItems.map((item, index) => (
                  <div key={item + index} className="cp-pl-8 cp-pt-4">
                    {item}
                  </div>
                ))}
              </div>
              {details.additional_items.length > 3 && (
                <CpButton
                  btnType="flat"
                  className="cp-mt-8"
                  onClick={() => setExpandedList(!expandedList)}
                >
                  {expandedList ? "Hide" : "View more"}
                </CpButton>
              )}
            </div>
          )}
          {transcriptsId && (
            <div className="cp-mt-16">
              <strong>Transcripts ID</strong>: {transcriptsId}
            </div>
          )}
        </CpModal.Body>
      )}
      {details?.show_integration_settings_link && (
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            anchor
            href="/#/global-settings/integrations"
          >
            View integration settings
          </CpButton>
        </CpModal.Footer>
      )}
    </CpModal>
  );
}
