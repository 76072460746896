import React from "react";
import { isEmpty, noop } from "lodash";
import { forkJoin } from "rxjs";
import queryString from "query-string";
import { map, mergeMap, filter as rxFilter, pluck } from "rxjs/operators";
import Cancelable from "react-disposable-decorator";
import { Scoped, k, always } from "kremling";
import { DateTime } from "luxon";
import { NavContent } from "primary-navbar!sofe";
import {
  CpLoader,
  CpInlineNotification,
  CpButton,
  CpCard,
  CpSelectSingle,
} from "canopy-styleguide!sofe";
import { onPusher } from "fetcher!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { handleError } from "src/error";
import { MessageBanners } from "../common/message-banner/message-banner.component";
import { Reports } from "./reports/reports.component";
import Documents from "./documents/documents.component";
import GutterNavigator from "src/client-transcripts/gutter-navigator.component";
import TranscriptRequestDialog from "../e-services/transcripts-request-dialog.component";
import TranscriptRequestSettings from "../e-services/transcripts-request-settings.component";
import SettingsChangedModal from "./settings-changed-modal.component";
import TranscriptsStatusDetailsModal from "../common/transcripts-status-details-modal.component";
import TNFreeTrialUpgradeBanner from "../common/tn-upgrade-banner.component";
import IrsStatus from "src/common/irs-status.component";
import {
  getVersions,
  getViewMessage,
  disableViewMessage,
  getSuccessfulTranscriptsCount,
  getNoticesCount,
  getIrsOrganizations,
} from "src/resources/transcripts.resource";
import { getClient } from "src/resources/clients.resource";
import { notifyAnalytics } from "src/resources/analytics.resource";
import {
  transcriptKeys,
  transcriptTypes,
  businessTranscriptTypes,
} from "./documents/documents.helper";
import TranscriptsEmptyState from "./transcripts-empty-state.component";
import { transcriptStatusTypes } from "../common/transcripts-status.helper";
import { viewKeys } from "./wrapper.helper";
import { transcriptsPrint } from "./capture-operations";

@Cancelable
@UserTenantProps({
  permissions: {
    hasTranscriptsPull: "transcripts_pull",
    hasUnlimitedPulls: "transcripts_pull_unlimited",
  },
})
export default class ClientTranscripts extends React.Component {
  constructor(props) {
    super(props);
    const clientId = props.match.params.clientId;

    this.state = {
      versions: [],
      client: {},
      clientId,
      loading: true,
      selectedVersion: {},
      selectedViewKey: props.selectedView || viewKeys.reports,
      selectedYear: new Date().getFullYear() - 1,
      selectedMenuItem:
        props.selectedView === viewKeys.reports
          ? "Account Overview"
          : transcriptKeys.ACCOUNT_TRANSCRIPT,
      newTranscript: null,
      showRequestModal: false,
      showSettingsModal: false,
      showSettingsChangedModal: false,
      disableRequestBtn: false,
      showStatusDetailModal: false,
      comparedVersion: {},
      comparingVersions: false,
      unsuccessfulReports: [],
      selectedSubDoc: "",
      reports: {},
      transcriptsCount: null,
      noticesCount: null,
      organizations: [],
      contactSearch: "",
      selectedContact: null,
    };
  }

  componentDidMount() {
    const parsedQueryString = queryString.parse(this.props.location.search);
    const { year, subtype, contact_id } = parsedQueryString;

    this.getVersions(contact_id);
    this.transcriptsPusherHandler();
    this.getViewMessageBool();
    this.fetchOrganizations();
    this.ispLoginPusherHandler();

    if (year) {
      this.setSelectedYear(year);
    }

    if (subtype) {
      this.setSelectedSubdoc(this.normalizeTypeId(subtype));
    }

    if (this.props.loggedInUser && !this.props.permissions.hasUnlimitedPulls) {
      this.fetchTranscriptsNoticesCount();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedView !== this.props.selectedView) {
      this.setSelectedViewKey(this.props.selectedView);
    }

    if (prevProps.location.search !== this.props.location.search) {
      const parsedQueryString = queryString.parse(this.props.location.search);
      const { year, subtype } = parsedQueryString;
      if (year) {
        this.setSelectedYear(year);
      }

      if (subtype) {
        this.setSelectedSubdoc(this.normalizeTypeId(subtype));
      }
    }

    if (
      !prevProps.loggedInUser &&
      this.props.loggedInUser &&
      !this.props.permissions.hasUnlimitedPulls
    ) {
      this.fetchTranscriptsNoticesCount();
    }
  }

  render() {
    const {
      client,
      loading,
      selectedVersion,
      selectedViewKey,
      selectedYear,
      selectedSubDoc,
      selectedMenuItem,
      versions,
      cafStatuses,
      newTranscript,
      showRequestModal,
      showSettingsModal,
      showSettingsChangedModal,
      disableRequestBtn,
      showStatusDetailModal,
      comparedVersion,
      comparingVersions,
      unsuccessfulReports,
      reports,
      transcriptsCount,
      noticesCount,
      organizations,
      contactSearch,
      selectedContact,
    } = this.state;

    const inlineNotificationContent = this.getInlineNotificationContent();
    const noticesLimitReached =
      noticesCount?.successful_count >= noticesCount?.limit;
    const transcriptsLimitReached =
      transcriptsCount?.successful_count >= transcriptsCount?.limit;
    const showUpgradeBanner = noticesLimitReached || transcriptsLimitReached;
    const contactsList = client?.contacts;
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant.crm_status === "crm_hierarchy_complete";

    return (
      <Scoped css={css}>
        <div>
          {loading ? (
            <div className="transcript-loader-wrapper">
              <CpLoader size="lg" />
            </div>
          ) : (
            <div>
              <NavContent
                clientMenuPossible={true}
                hasTopnavSecondary={false}
                style={{ marginLeft: 80, padding: "0px" }}
              >
                <div className="cp-flex-column">
                  <MessageBanners />
                </div>
                <CpCard className="client-sync-banner">
                  {isCrmHeirarchy && !client.is_business && (
                    <div className="cp-ml-16">
                      Display transcripts for:{" "}
                      <CpSelectSingle
                        data={contactsList || []}
                        disabled={!contactsList?.length}
                        onChange={this.setSelectedContact}
                        placeholder="Select contact"
                        searchFilter
                        searchOnChange={(contactSearch) =>
                          this.setState({ contactSearch })
                        }
                        searchValue={contactSearch}
                        value={selectedContact || ""}
                        transformData={(val) => {
                          return {
                            id: val.id,
                            name:
                              val.name || `${val.first_name} ${val.last_name}`,
                            subName: val.primary_email || "",
                          };
                        }}
                      />
                    </div>
                  )}
                  <div style={{ flex: 1 }} />
                  <IrsStatus
                    organizations={organizations}
                    fetchOrganizations={this.fetchOrganizations}
                    transcriptsLimitReached={transcriptsLimitReached}
                  />
                </CpCard>
                {!isEmpty(selectedVersion) ? (
                  <div className="transcript-wrapper">
                    <GutterNavigator
                      selectedViewKey={selectedViewKey}
                      selectedItem={selectedMenuItem}
                      setSelectedItem={this.setSelectedMenuItem}
                      menuItems={{
                        documents: client.is_business
                          ? businessTranscriptTypes
                          : transcriptTypes,
                        reports,
                      }}
                      disabledTabs={
                        selectedViewKey === viewKeys.documents
                          ? false
                          : isEmpty(reports)
                      }
                      cafStatuses={cafStatuses[selectedVersion?.id]}
                      versions={versions}
                      selectedVersion={selectedVersion}
                      setSelectedVersion={this.setSelectedVersion}
                      comparedVersion={comparedVersion}
                      setComparedVersion={this.setComparedVersion}
                      setComparingVersions={(comparingVersions) =>
                        this.setState({ comparingVersions })
                      }
                      comparingVersions={comparingVersions}
                      showCompareVersionSelector={
                        selectedViewKey === viewKeys.documents &&
                        versions?.length > 1 &&
                        selectedVersion?.category !==
                          transcriptStatusTypes.PARTIAL_SUCCESS
                      }
                      unsuccessfulReports={unsuccessfulReports}
                      clientId={client?.id}
                    />

                    <div className="cp-flex-column">
                      {showUpgradeBanner && (
                        <div
                          className={always("upgrade-banner-wrapper").m(
                            "upgrade-banner-wrapper-document",
                            selectedViewKey === viewKeys.documents
                          )}
                        >
                          <TNFreeTrialUpgradeBanner
                            transcriptsCount={transcriptsCount}
                            noticesCount={noticesCount}
                            noticesLimitReached={noticesLimitReached}
                            transcriptsLimitReached={transcriptsLimitReached}
                          />
                        </div>
                      )}
                      {newTranscript && (
                        <div
                          className={always("inline-notification-wrapper").m(
                            "inline-notification-document",
                            selectedViewKey === viewKeys.documents
                          )}
                        >
                          <CpInlineNotification
                            {...inlineNotificationContent}
                          />
                        </div>
                      )}
                      {selectedViewKey === viewKeys.reports && (
                        <Reports
                          showHideRequestModal={this.showHideRequestModal}
                          showHideSettingsModal={this.showHideSettingsModal}
                          client={client}
                          selectedViewKey={selectedViewKey}
                          selectedItem={selectedMenuItem}
                          version={selectedVersion}
                          disableRequestBtn={
                            disableRequestBtn || transcriptsLimitReached
                          }
                          setUnsuccessfulReports={this.setUnsuccessfulReports}
                          unsuccessfulReports={unsuccessfulReports}
                          setReports={this.setReports}
                          reports={reports}
                        />
                      )}
                      {selectedViewKey === viewKeys.documents && (
                        <Documents
                          showHideRequestModal={this.showHideRequestModal}
                          showHideSettingsModal={this.showHideSettingsModal}
                          client={client}
                          selectedYear={selectedYear}
                          selectedSubDoc={selectedSubDoc}
                          setSelectedYear={this.setSelectedYear}
                          setSelectedSubDoc={this.setSelectedSubdoc}
                          selectedItem={selectedMenuItem}
                          version={selectedVersion}
                          disableRequestBtn={
                            disableRequestBtn || transcriptsLimitReached
                          }
                          comparedVersion={comparedVersion}
                          comparingVersions={comparingVersions}
                          isCrmHeirarchy={isCrmHeirarchy}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={"cp-flex-center cp-flex-column"}>
                    {showUpgradeBanner && (
                      <TNFreeTrialUpgradeBanner
                        transcriptsCount={transcriptsCount}
                        noticesCount={noticesCount}
                        noticesLimitReached={noticesLimitReached}
                        transcriptsLimitReached={transcriptsLimitReached}
                      />
                    )}
                    {newTranscript && (
                      <div className="inline-notification-wrapper cp-mt-8 cp-ml-0">
                        <CpInlineNotification {...inlineNotificationContent} />
                      </div>
                    )}
                    <TranscriptsEmptyState
                      title="Transcripts"
                      headText="Request a transcript"
                      subheadText={
                        this.props.hasTranscriptsPull
                          ? "There are no successful transcripts for this client. Use your IRS e-services account and your power of attorney to get started."
                          : "Account permission needed to pull transcripts from the IRS."
                      }
                      icon="es_transcripts_list"
                      showHideRequestModal={this.showHideRequestModal}
                      requestBtnDisabled={
                        disableRequestBtn || transcriptsLimitReached
                      }
                    />
                  </div>
                )}
              </NavContent>
            </div>
          )}
          <TranscriptsStatusDetailsModal
            show={showStatusDetailModal}
            close={() => this.setState({ showStatusDetailModal: false })}
            statusObj={newTranscript?.statusObj}
            transcriptsId={newTranscript?.id}
          />
          <TranscriptRequestDialog
            show={showRequestModal}
            onClose={() => this.showHideRequestModal(false)}
            callback={() =>
              this.setState({
                newTranscript: {
                  statusObj: { type: transcriptStatusTypes.PENDING },
                },
                disableRequestBtn: true,
              })
            }
            clientId={client?.id}
            transcriptsCount={transcriptsCount}
            fetchOrganizations={this.fetchOrganizations}
            preselectedContactId={selectedContact?.id}
          />
          <TranscriptRequestSettings
            show={showSettingsModal}
            onClose={() => this.showHideSettingsModal(false)}
            clientId={client?.id}
            onDeleteCb={(clientId, undo) => {
              if (undo) {
                this.getVersions();
              } else {
                this.setState({ selectedVersion: {}, newTranscript: null });
              }
            }}
            contactId={selectedContact?.id}
          />
          <SettingsChangedModal
            show={showSettingsChangedModal}
            close={() => {
              this.setState({ showSettingsChangedModal: false });
              this.setViewMessage();
            }}
          />
        </div>
      </Scoped>
    );
  }

  setSelectedContact = (selectedContact) => {
    this.setState({ selectedContact }, this.getVersions);
  };

  normalizeTypeId = (typeId) => {
    return typeId.trim().replace(/\s/g, "_");
  };

  getViewMessageBool = () => {
    const { clientId } = this.state;

    if (!this.props.permissions.hasTranscriptsPull) return;

    this.props.cancelWhenUnmounted(
      getViewMessage(clientId).subscribe((viewMessage) => {
        if (viewMessage) {
          this.setState({ showSettingsChangedModal: true });
        }
      }, handleError)
    );
  };

  setViewMessage = () => {
    const { clientId } = this.state;

    this.props.cancelWhenUnmounted(
      disableViewMessage(clientId).subscribe(() => {}, handleError)
    );
  };

  setUnsuccessfulReports = (unsuccessfulReports = []) =>
    this.setState({ unsuccessfulReports });

  getInlineNotificationContent = () => {
    const { newTranscript } = this.state;
    let message =
      "There is currently another transcript request that is being processed by the IRS.";
    let cta = <div />;
    let type = "info";
    let icon = "";

    if (
      newTranscript?.statusObj?.category === transcriptStatusTypes.SUCCESS ||
      newTranscript?.newSuccessPulled ||
      newTranscript?.statusObj?.readyToView
    ) {
      message = "Your request has finished processing and is now available.";
      cta = (
        <CpButton btnType="secondary" onClick={this.getVersions}>
          View new version
        </CpButton>
      );
    } else if (
      newTranscript?.statusObj?.category ===
      transcriptStatusTypes.PARTIAL_SUCCESS
    ) {
      message =
        "Your latest request was partially successful. Some forms may be missing.";
      cta = (
        <CpButton
          btnType="secondary"
          onClick={() => this.setState({ showStatusDetailModal: true })}
        >
          View details
        </CpButton>
      );
    } else if (
      newTranscript?.statusObj?.category === transcriptStatusTypes.ERROR
    ) {
      message = "Your latest request was not successful.";
      cta = (
        <CpButton
          btnType="secondary"
          onClick={() => this.setState({ showStatusDetailModal: true })}
        >
          View details
        </CpButton>
      );
      type = "warning";
    } else if (
      newTranscript?.statusObj?.category === transcriptStatusTypes.RETRYING
    ) {
      message = newTranscript?.statusObj?.status;
      icon = "af-retry-large";
      cta = (
        <CpButton
          btnType="secondary"
          onClick={() => this.setState({ showStatusDetailModal: true })}
        >
          View details
        </CpButton>
      );
      type = "info";
    }

    return { type, cta, message, icon };
  };

  fetchTranscriptsNoticesCount = () => {
    this.props.cancelWhenUnmounted(
      forkJoin([getSuccessfulTranscriptsCount(), getNoticesCount()]).subscribe(
        ([transcriptsCount, noticesCount]) => {
          const noticesLimitReached =
            noticesCount?.successful_count >= noticesCount?.limit;
          const transcriptsLimitReached =
            transcriptsCount?.successful_count >= transcriptsCount?.limit;
          if (noticesLimitReached || transcriptsLimitReached) {
            window.dispatchEvent(
              new CustomEvent("cp:tn-trial-limits-reached", {
                detail: {
                  notices: noticesLimitReached,
                  transcripts: transcriptsLimitReached,
                },
              })
            );
          }

          this.setState({ transcriptsCount, noticesCount });
        },
        handleError
      )
    );
  };

  fetchOrganizations = () => {
    this.props.cancelWhenUnmounted(
      getIrsOrganizations().subscribe((organizations) => {
        const validOrgs = organizations.filter((org) => !org.deleted_at);
        this.setState({ organizations: validOrgs });
      }, handleError)
    );
  };

  getVersions = (contactIdParam) => {
    const { cancelWhenUnmounted } = this.props;
    const { clientId, selectedContact } = this.state;
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant.crm_status === "crm_hierarchy_complete";
    this.setState({ loading: true });

    cancelWhenUnmounted(
      getClient(clientId)
        .pipe(
          mergeMap((client) => {
            let contactId = selectedContact?.id;
            if (!contactId) {
              const contact =
                (contactIdParam
                  ? client.contacts?.find((c) => c.id == contactIdParam)
                  : client.contacts?.find((c) => c.is_primary)) ||
                client.contacts?.[0];
              contactId = contact?.id;
              this.setState({ selectedContact: contact });
            }
            return getVersions(
              clientId,
              !client.is_business ? contactId : undefined,
              isCrmHeirarchy
            ).pipe(
              map((res) => {
                const versions = res.sort(
                  (a, b) =>
                    DateTime.fromISO(b.created_at).toMillis() -
                    DateTime.fromISO(a.created_at).toMillis()
                );
                return { client, versions };
              })
            );
          })
        )
        .subscribe(({ client, versions }) => {
          const viewablePulls = versions.filter((version) => {
            return (
              version.category === transcriptStatusTypes.SUCCESS ||
              version.category === transcriptStatusTypes.PARTIAL_SUCCESS ||
              version.ready_to_view
            );
          });
          const newestStatus = {
            status: versions[0]?.status,
            category: versions[0]?.category,
            readyToview: versions[0]?.ready_to_view,
          };

          this.setState({
            versions: viewablePulls,
            selectedVersion: !isEmpty(viewablePulls) ? viewablePulls[0] : {},
            client,
            loading: false,
            cafStatuses: versions.reduce(
              (acc, v) => ({
                ...acc,
                [v.id]: JSON.parse(v.caf_results),
                version: v.id,
              }),
              {}
            ),
            disableRequestBtn:
              newestStatus.category === transcriptStatusTypes.PENDING,
            newTranscript:
              newestStatus.category === transcriptStatusTypes.SUCCESS ||
              !versions?.length
                ? null
                : { statusObj: newestStatus, id: versions[0]?.id },
            comparedVersion: viewablePulls[1]?.version ? viewablePulls[1] : {},
          });
        }, handleError)
    );
  };

  ispLoginPusherHandler = () => {
    this.props.cancelWhenUnmounted(
      onPusher("transcripts-isp-login").subscribe(() => {
        this.fetchOrganizations();
      }, handleError)
    );
  };

  transcriptsPusherHandler = () => {
    const { clientId } = this.state;
    this.props.cancelWhenUnmounted(
      onPusher("transcripts")
        .pipe(
          rxFilter(
            (msg) =>
              msg.data_needed === "status_change" &&
              msg.details?.client_id == clientId
          ),
          pluck("details")
        )
        .subscribe((details) => {
          const { selectedVersion } = this.state;
          const selectedStatusSuccessful =
            selectedVersion?.category === transcriptStatusTypes.SUCCESS ||
            selectedVersion?.category ===
              transcriptStatusTypes.PARTIAL_SUCCESS ||
            selectedVersion.ready_to_view;
          const newTranscriptViewable =
            details.category === transcriptStatusTypes.SUCCESS ||
            details.category === transcriptStatusTypes.PARTIAL_SUCCESS ||
            details.ready_to_view;
          if (
            !this.props.permissions.hasUnlimitedPulls &&
            (details.category === transcriptStatusTypes.SUCCESS ||
              details.category === transcriptStatusTypes.PARTIAL_SUCCESS)
          ) {
            this.fetchTranscriptsNoticesCount();
          }

          if (
            // Refresh with the newly pulled transcripts if they don't have any transcripts
            newTranscriptViewable &&
            (isEmpty(selectedVersion) || !selectedStatusSuccessful)
          ) {
            this.getVersions();

            if (isEmpty(selectedVersion)) {
              this.setState({ showSettingsChangedModal: true });
            }
          } else if (selectedVersion?.id !== details.transcript_id) {
            this.setState({
              newTranscript: {
                statusObj: {
                  status: details.label,
                  category: details.category,
                  readyToView: details.ready_to_view,
                },
                id: details.transcript_id,
                newSuccessPulled: newTranscriptViewable,
              },
            });
          }

          if (
            details.category === transcriptStatusTypes.SUCCESS ||
            details.category === transcriptStatusTypes.PARTIAL_SUCCESS ||
            details.category === transcriptStatusTypes.ERROR
          ) {
            this.setState({ disableRequestBtn: false });
          }
        }, handleError)
    );
  };

  showHideRequestModal = (showRequestModal) => {
    this.setState({ showRequestModal });
  };

  showHideSettingsModal = (showSettingsModal) => {
    this.setState({ showSettingsModal });
  };

  setSelectedVersion = (selectedVersion) => {
    const { comparedVersion } = this.state;
    const newTranscript =
      selectedVersion?.category === transcriptStatusTypes.PARTIAL_SUCCESS
        ? { statusObj: selectedVersion, id: selectedVersion?.id }
        : null;

    if (comparedVersion?.id === selectedVersion.id) {
      return this.setState({
        selectedVersion,
        comparedVersion: {},
        newTranscript,
      });
    } else if (!selectedVersion.version) {
      return this.setState({
        selectedVersion,
        comparingVersions: false,
        newTranscript,
      });
    }

    this.setState({ selectedVersion, newTranscript });
    notifyAnalytics("transcripts.version_changed", {
      client_type: this.state.client.is_business ? "business" : "individual",
    });
  };

  setComparedVersion = (comparedVersion) => {
    this.setState({ comparedVersion });
    notifyAnalytics("transcripts.version_compared", {
      client_type: this.state.client.is_business ? "business" : "individual",
    });
  };

  setSelectedViewKey = (selectedViewKey) => {
    this.setState({
      selectedViewKey,
      selectedMenuItem:
        selectedViewKey === viewKeys.documents
          ? transcriptKeys.ACCOUNT_TRANSCRIPT
          : "Account Overview",
    });
  };

  setSelectedYear = (selectedYear, cb = noop) => {
    this.setState({ selectedYear }, cb);
  };

  setSelectedSubdoc = (selectedSubDoc) => {
    this.setState({ selectedSubDoc });
  };

  setSelectedMenuItem = (selectedMenuItem, selectedViewKey) => {
    if (this.state.selectedMenuItem === selectedMenuItem) return;
    this.setState(() => ({ selectedMenuItem, selectedViewKey }));
    notifyAnalytics(
      `transcripts.tab_${this.state.selectedViewKey}_${selectedMenuItem}`,
      {
        client_type: this.state.client.is_business ? "business" : "individual",
      }
    );
  };

  setReports = (reports) => {
    this.setState({ reports, selectedMenuItem: Object.keys(reports)[0] });
  };

  transcriptsPrintAll = () => {
    const { selectedVersion, versions } = this.state;
    if (!selectedVersion || isEmpty(versions)) return;
    const version = versions.find(
      (version) => version.id === selectedVersion?.id
    );
    transcriptsPrint(version);
  };
}

const css = k`
  .transcript-wrapper {
    display: flex;
  }

  .transcript-loader-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 32px;
  }

  .client-sync-banner {
    display: flex;
    align-items: center;
    height: 6.5rem;
    position: sticky;
    top: 11.8rem;
    z-index: 10;
  }

  .inline-notification-wrapper {
    margin-left: 27.5rem;
    padding: 2.4rem 0 0 2.4rem;
    width: 90rem;
  }

  .inline-notification-document {
    margin-left: 36rem;
  }

  .upgrade-banner-wrapper {
    margin-left: 27.5rem;
    padding: 2.4rem 0 0 2.4rem;
    width: calc(100% - 30rem);
  }

  .upgrade-banner-wrapper-document {
    margin-left: 36rem;
    width: calc(100% - 36rem);
  }
`;
