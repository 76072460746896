import React from "react";
import { Scoped, k } from "kremling";
import { CpButton, CpIcon, CpDropdown, CpHeader } from "canopy-styleguide!sofe";
import { UserTenantProps, hasAccess } from "cp-client-auth!sofe";

@UserTenantProps()
export default class DocumentContentHeader extends React.Component {
  state = { showRequestDialog: false };

  render() {
    const {
      download,
      heading,
      print,
      year,
      saveToFiles,
      showHideRequestModal,
      showHideSettingsModal,
      disableRequestBtn,
    } = this.props;
    const hasClientAccess = hasAccess(this.props.loggedInUser)(
      ["files_client", "files_upload_move", "files"],
      true
    );
    const hasTranscriptsPull = hasAccess(this.props.loggedInUser)(
      "transcripts_pull"
    );

    return (
      <Scoped css={css}>
        <CpHeader level={3}>
          <div className="document-page-headers">{heading}</div>
          <div className="cp-flex-center">
            {hasTranscriptsPull && (
              <CpButton
                btnType="primary"
                className="cp-mr-12"
                onClick={() => showHideRequestModal(true)}
                disabled={disableRequestBtn}
              >
                Request transcripts
              </CpButton>
            )}
            <CpDropdown
              contentWidth={200}
              position="bottom-end"
              renderTrigger={({ toggle }) => (
                <CpButton
                  icon="misc-kabob"
                  onClick={toggle}
                  aria-label="Transcripts actions"
                />
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  <button id="print-btn" onClick={print}>
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name="misc-printer"
                    />
                    Print
                  </button>
                  <button
                    id="download-btn"
                    onClick={() => download(false, year)}
                  >
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name="af-open-down"
                    />
                    Download
                  </button>
                  {hasClientAccess && (
                    <button onClick={saveToFiles} disabled={disableRequestBtn}>
                      <CpIcon
                        className="cp-select-list__icon-left"
                        name="folder-c"
                        fill={
                          disableRequestBtn
                            ? "var(--cp-color-input-disabled-search-icon)"
                            : "var(--cp-color-app-icon)"
                        }
                      />
                      <span
                        style={{
                          color:
                            disableRequestBtn &&
                            "var(--cp-color-input-disabled-search-icon)",
                        }}
                      >
                        Save to Canopy Files
                      </span>
                    </button>
                  )}
                  <button onClick={() => showHideSettingsModal(true)}>
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name="misc-gear"
                    />
                    Transcripts Settings
                  </button>
                </div>
              )}
            />
          </div>
        </CpHeader>
      </Scoped>
    );
  }
}

const css = k`
  .document-page-headers {
    font-size: 20px;
    font-weight: 400;
  }
`;
