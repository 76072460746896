import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Scoped, k } from "kremling";
import { NavContent, TopnavSecondary } from "primary-navbar!sofe";
import { CpLoader, CpHeader } from "canopy-styleguide!sofe";
import { warningToast } from "toast-service!sofe";
import { handleError } from "src/error";
import { sendIrsAuthCode } from "../resources/transcripts.resource";

export default function IRSAuthorized() {
  const { search } = useLocation();
  useEffect(() => {
    const code = new URLSearchParams(search).get("code");

    if (code) {
      const isTest =
        document.referrer?.includes("api.alt") ||
        document.referrer?.includes("TEST");
      sendAuthCode(code, !isTest);
    } else {
      warningToast(
        "We were unable to connect to the IRS because the IRS responded without an authentication code."
      );
    }
  }, []);

  const sendAuthCode = (code, isProd) => {
    sendIrsAuthCode(code, isProd).subscribe(
      () => window.self.close(),
      (err) => {
        handleError(err);
        window.self.close();
      }
    );
  };

  return (
    <NavContent
      hasTopnavSecondary={true}
      style={{ marginLeft: 80, padding: "0px" }}
    >
      <TopnavSecondary>
        <CpHeader style={{ backgroundColor: "var(--cp-color-well-l1-bg)" }}>
          <div className="cps-subheader">Transcripts</div>
        </CpHeader>
      </TopnavSecondary>
      <Scoped css={css}>
        <div className="irs-auth-loader">
          <CpLoader size="lg" />
          <div className="auth-loader-text">Authorizing IRS login...</div>
        </div>
      </Scoped>
    </NavContent>
  );
}

const css = k`
  .irs-auth-loader {
    margin-top: 16rem;
  }

  .auth-loader-text {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;
