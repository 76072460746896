import React from "react";
import {
  cloneDeep,
  filter,
  findKey,
  flatten,
  forEach,
  get,
  groupBy,
  head,
  isArray,
  isEmpty,
  last,
  map,
} from "lodash";
import { Scoped, k } from "kremling";
import Cancelable from "react-disposable-decorator";
import { CpLoader } from "canopy-styleguide!sofe";
import { handleError } from "src/error";

import {
  sortSubDocuments,
  transcriptTypes,
  businessTranscriptTypes,
  pullMethodKeys,
} from "./documents.helper.js";
import {
  getVersionDocuments,
  getDocumentsComparison,
} from "src/resources/transcripts.resource.js";

import { DocumentYear } from "./document-year.component.js";
import DocumentContentHeader from "./document-content-header.component.js";
import { DocumentNavigator } from "./document-navigator.component.js";
import DocumentPrintDownloadModal from "./document-print-download-modal.component.js";
import SaveToFilesModal from "../save-files/save-to-files-modal.component";
import { notifyAnalytics } from "../../resources/analytics.resource";
import {
  transcriptsDownload,
  transcriptsPrint,
} from "src/client-transcripts/capture-operations.js";
import TranscriptsEmptyState from "../transcripts-empty-state.component";

@Cancelable
export default class Documents extends React.Component {
  constructor(props) {
    super(props);
    const documents = props.client.is_business
      ? cloneDeep(businessTranscriptTypes)
      : cloneDeep(transcriptTypes);

    this.state = {
      documents,
      documentsLoading: true,
      showPrintModal: false,
      modalAction: "print",
      showFilesModal: false,
    };

    this.documentYearRefs = [];
    this.scrollOffsetComplement = 400;
  }

  componentDidMount() {
    this.fetchDocuments();

    window.addEventListener("scroll", this.setCurrentDocument);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.setCurrentDocument);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.version.id !== this.props.version.id ||
      (this.props.comparingVersions &&
        prevProps.comparedVersion?.id !== this.props.comparedVersion?.id) ||
      prevProps.comparingVersions !== this.props.comparingVersions
    ) {
      this.fetchDocuments();
    }

    if (this.state.selectedDocumentKey !== prevState.selectedDocumentKey) {
      this.setDefaultDoc();
    }

    if (prevProps.client.is_business !== this.props.client.is_business) {
      this.setState(
        {
          documents: this.props.client.is_business
            ? businessTranscriptTypes
            : transcriptTypes,
        },
        this.fetchDocuments()
      );
    }

    const documentsLoaded =
      prevState.documentsLoading !== this.state.documentsLoading;
    const activeMenuItemChanged =
      this.props.selectedItem !== prevProps.selectedItem;

    if (activeMenuItemChanged || documentsLoaded) {
      const domNode =
        document.getElementById(
          `subdoc-${this.props.selectedYear}-${this.props.selectedSubDoc}`
        ) || document.getElementById(`year-${this.props.selectedYear}`);

      if (domNode) {
        this.scrollToNode(domNode);
      }
    }
  }

  render() {
    const { selectedYear, selectedSubDoc, selectedItem, disableRequestBtn } =
      this.props;
    const {
      documents,
      documentsLoading,
      showPrintModal,
      modalAction,
      showFilesModal,
    } = this.state;
    const clonedDocuments = cloneDeep(documents);

    const selectedDocumentKey = selectedItem;

    const targetDocs =
      (clonedDocuments[selectedDocumentKey] &&
        isArray(clonedDocuments[selectedDocumentKey].documents) &&
        clonedDocuments[selectedDocumentKey].documents) ||
      [];

    // build documents type object with value, label, and available years
    const documentTypes = map(clonedDocuments, (document) => {
      return {
        value: document.pullMethodKey,
        label: document.title,
        availableYears: document.availableYears,
      };
    });

    return (
      <Scoped css={css}>
        <DocumentPrintDownloadModal
          showPrintDownloadModal={showPrintModal}
          close={this.hideModal}
          documentTypes={documentTypes}
          print={this.printRecords}
          download={this.downloadRecords}
          action={modalAction}
        />
        <SaveToFilesModal
          showSaveToFilesModal={showFilesModal}
          client={this.props.client}
          close={this.hideModal}
          documentTypes={documentTypes}
          type="documents"
          versionId={this.props.version.id}
        />
        {documentsLoading ? (
          <div className="loader-position">
            <CpLoader size="lg" center />
          </div>
        ) : (
          <div className="document-wrapper">
            <div className="doc-nav-wrapper">
              {filter(clonedDocuments, (document) => document.enabled).length >
                0 && targetDocs.length > 0 ? (
                <div>
                  <DocumentNavigator
                    documents={targetDocs}
                    selectedYear={selectedYear}
                    selectedSubDoc={selectedSubDoc}
                    navigateToSubDoc={this.navigateToSubDoc}
                    navigateToYear={this.navigateToYear}
                    isCrmHeirarchy={this.props.isCrmHeirarchy}
                  />
                  <div className="documents-section">
                    {clonedDocuments[selectedDocumentKey] &&
                      isArray(clonedDocuments[selectedDocumentKey].documents) &&
                      clonedDocuments[selectedDocumentKey].documents.map(
                        (documentYear, idx) => {
                          const yearInView = parseInt(selectedYear);
                          const docYearNum = parseInt(head(documentYear).year);
                          const yearsDiff = yearInView - docYearNum;

                          // renders only 7 documents to the DOM at a time. The one selected and the 3 below or above the selected doc
                          if (
                            docYearNum !== yearInView &&
                            [-3, -2, -1, 1, 2, 3].indexOf(yearsDiff) < 0
                          ) {
                            // placeholder div for a document so that the scrollbar isn't funky
                            return (
                              <div key={idx} style={{ height: "2200px" }} />
                            );
                          }
                          if (idx === 0) this.documentYearRefs = [];
                          return (
                            <React.Fragment key={head(documentYear).year}>
                              <div className="cps-flexible-focus cps-card-table cps-card header-card">
                                <DocumentContentHeader
                                  showHideRequestModal={
                                    this.props.showHideRequestModal
                                  }
                                  showHideSettingsModal={
                                    this.props.showHideSettingsModal
                                  }
                                  year={head(documentYear).year}
                                  download={this.download}
                                  saveToFiles={this.showFilesModal}
                                  print={this.print}
                                  heading={`${
                                    transcriptTypes[selectedDocumentKey].title
                                  }: ${head(documentYear).year}`}
                                  disableRequestBtn={disableRequestBtn}
                                />
                              </div>

                              <section
                                key={`year-${head(documentYear).year}`}
                                id={`year-${head(documentYear).year}`}
                              >
                                <div className="doc-year">
                                  <DocumentYear
                                    ref={(ref) =>
                                      (this.documentYearRefs[idx] = {
                                        ref,
                                        subDocs: documentYear,
                                      })
                                    }
                                    subDocs={documentYear}
                                  />
                                </div>
                              </section>
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              ) : (
                <div className="no-docs">
                  <TranscriptsEmptyState
                    showHideRequestModal={this.props.showHideRequestModal}
                    title={transcriptTypes[selectedDocumentKey].title}
                    headText="No document found"
                    subheadText="This document doesn't exist for this transcript type."
                    requestBtnDisabled={disableRequestBtn}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Scoped>
    );
  }

  isOnScreen = (coords) => {
    const height = window.innerHeight || document.documentElement.clientHeight;

    return coords.top <= height && coords.right > 0 && coords.bottom > 0;
  };

  setOnScreenSubDoc = (subDocs) => {
    const onScreenIds = subDocs.reduce((onScreens, doc) => {
      const docId = `subdoc-${subDocs[0].year}-${doc.subtype}`
        .trim()
        .replace(/\s/g, "_");
      const node = document.getElementById(docId);

      return node && this.isOnScreen(node.getBoundingClientRect())
        ? [...onScreens, docId]
        : onScreens;
    }, []);

    if (isEmpty(onScreenIds)) {
      return;
    }

    if (onScreenIds.length === 1) {
      return this.props.setSelectedSubDoc(head(onScreenIds));
    }

    return this.props.setSelectedSubDoc(last(onScreenIds));
  };

  setCurrentDocument = () => {
    if (isEmpty(this.documentYearRefs)) return;

    const docsInView = this.documentYearRefs.filter((docYear) => {
      if (docYear.ref) {
        const coords = docYear.ref.getBoundingClientRect();
        return this.isOnScreen(coords);
      }
    });

    // if there are multiple docs showing on the page, choose the second doc on the screen
    // since it is most likely to be in the middle of the page
    if (docsInView.length > 1) {
      this.setOnScreenSubDoc(docsInView[1].subDocs);
      return this.props.setSelectedYear(docsInView[1].subDocs[0].year);
    }

    if (docsInView.length) {
      this.setOnScreenSubDoc(docsInView[0].subDocs);
      return this.props.setSelectedYear(docsInView[0].subDocs[0].year);
    }
  };

  getElementOffset = (domEl) => {
    const rect = domEl.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  scrollToNode = (node) =>
    window.scrollTo(
      0,
      this.getElementOffset(node).top - this.scrollOffsetComplement
    );

  navigateToSubDoc = (subDocId) => {
    this.props.setSelectedSubDoc(subDocId);
    const domEl = document.querySelector(`#${subDocId}`);

    if (domEl) {
      this.scrollToNode(domEl);
    }
  };

  navigateToYear = (year) => {
    this.props.setSelectedYear(year, () => {
      const domNode = document.querySelector(`#year-${year}`);

      if (domNode) {
        this.scrollToNode(domNode);
      }
    });
  };

  setDefaultDoc = () => {
    const { setSelectedSubDoc, selectedYear } = this.props;
    const { documents, selectedDocumentKey } = this.state;
    const year = parseInt(selectedYear, 10);

    const defaultDoc = flatten(
      get(documents, `[${selectedDocumentKey}].documents`, [])
    ).reduce(
      (found, current) =>
        !found && parseInt(current.year, 10) === year ? current : found,
      null
    );

    if (defaultDoc) {
      const docId = `subdoc-${defaultDoc.year}-${defaultDoc.subtype}`
        .trim()
        .replace(/\s/g, "_");
      setSelectedSubDoc(docId);
    }
  };

  fetchDocuments = () => {
    const { version, comparingVersions, comparedVersion } = this.props;
    const { documents, selectedDocumentKey } = this.state;
    if (comparingVersions && isEmpty(comparedVersion)) {
      return;
    }

    this.setState({
      documentsLoading: true,
    });

    let getDocuments = getVersionDocuments;
    if (comparingVersions) {
      getDocuments = getDocumentsComparison;
    }

    const obs = getDocuments(version.id, comparedVersion?.id).subscribe(
      ({ transcript_records: transcriptRecords }) => {
        if (transcriptRecords?.length > 0) {
          const documentsGroup = groupBy(
            transcriptRecords,
            (record) => record.type
          );

          forEach(documentsGroup, (docGroup, key) => {
            documents[findMyKey(key)] = {
              pullMethodKey: pullMethodKeys[key].key,
              ...documents[findMyKey(key)],
              ...sortSubDocuments(docGroup, key),
              enabled: !isEmpty(docGroup),
            };
          });

          let newSelectedDocumentKey = findKey(documents, (document, key) => {
            return (
              key === selectedDocumentKey &&
              get(document, "documents[0]", []).length > 0
            );
          });

          if (!newSelectedDocumentKey) {
            newSelectedDocumentKey = findKey(
              documents,
              (document) => get(document, "documents[0]", []).length > 0
            );
          }

          this.setState(
            () => ({
              documents,
              selectedDocumentKey: newSelectedDocumentKey,
              documentsLoading: false,
            }),
            this.setDefaultDoc
          );
        } else {
          // No Records
          this.setState(() => ({ documentsLoading: false }));
        }
      },
      handleError
    );

    // type.title === key accounts for transcripts pulled before the new api
    function findMyKey(key) {
      return findKey(
        transcriptTypes,
        (type) => type.title === key || type.apiType === key
      );
    }

    this.props.cancelWhenUnmounted(obs);
  };

  downloadRecords = (
    allYears = false,
    years = [],
    allTypes = false,
    types = []
  ) => {
    notifyAnalytics(`transcripts.documents_downloaded`, {
      client_type: this.props.client.is_business ? "business" : "individual",
    });
    const { version } = this.props;

    const filter = {
      types: !isEmpty(types) && !allTypes ? types : [],
      years: !isEmpty(years) && !allYears ? years : [],
    };

    transcriptsDownload(version.id, filter, this.hideModal);
  };

  download = () =>
    this.setState({
      showPrintModal: true,
      showFilesModal: false,
      modalAction: "download",
    });

  print = () => {
    this.setState({
      showPrintModal: true,
      showFilesModal: false,
      modalAction: "print",
    });
  };

  printRecords = (
    allYears = false,
    years = [],
    allTypes = false,
    types = []
  ) => {
    notifyAnalytics(`transcripts.documents_printed`, {
      client_type: this.props.client.is_business ? "business" : "individual",
    });
    const { version } = this.props;

    const filter = {
      types: !isEmpty(types) && !allTypes ? types : [],
      years: !isEmpty(years) && !allYears ? years : [],
    };

    transcriptsPrint(version, filter, this.hideModal);
  };

  hideModal = () =>
    this.setState({
      showPrintModal: false,
      showFilesModal: false,
    });

  showFilesModal = () =>
    this.setState({
      showFilesModal: true,
      showPrintModal: false,
    });
}

const css = k`
  .document-wrapper {
    display: flex;
    margin-left: 275px;
  }

  .doc-nav-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;
  }

  .header-card,
  .doc-year {
    width: 100%;
  }

  .doc-year {
    margin-bottom: 56px;
    border-radius: 0rem;
  }

  .no-docs {
    margin-top: -32px;
    width: 90rem;
    margin-left: 8.5rem;
  }

  .documents-section {
    margin-left: 108px;
    overflow-x: scroll;
    max-width: calc(100vw - 385px);
  }

  .loader-position {
    margin-top: 18rem;
  }
`;
