import React, { useContext, useState } from "react";
import { Scoped, k } from "kremling";
import { CpLoader, CpPagination } from "canopy-styleguide!sofe";
import TranscriptsList from "./transcripts-list.component";
import TranscriptsStatusDetailsModal from "src/common/transcripts-status-details-modal.component";
import TranscriptRequestSettings from "../e-services/transcripts-request-settings.component";
import { TranscriptsContext } from "./use-transcripts-data.hook";

export const TranscriptsListWrapper = ({
  transcriptsCount,
  activeOrgs,
  setShowRequestDialog,
  setRequestDialogClientId,
  settingsModalIds,
  setSettingsModalIds,
  isCrmHeirarchy,
}) => {
  const { loading, page, lastPage, actions } = useContext(TranscriptsContext);
  const [statusModalsDetails, setStatusModalsDetails] = useState(null);

  return loading ? (
    <div className="loader-wrapper">
      <CpLoader size="lg" />
    </div>
  ) : (
    <Scoped css={css}>
      <TranscriptsList
        setStatusObj={setStatusModalsDetails}
        setShowRequestDialog={(showRequestDialog, clientId = "") => {
          setShowRequestDialog(showRequestDialog);
          setRequestDialogClientId(clientId);
        }}
        showSettingsModal={(settingsModalId, contactId = "") => {
          setSettingsModalIds({
            clientId: settingsModalId,
            contactId,
          });
        }}
        transcriptsCount={transcriptsCount}
        activeOrgs={activeOrgs}
        isCrmHeirarchy={isCrmHeirarchy}
      />

      <TranscriptsStatusDetailsModal
        show={!!statusModalsDetails}
        close={() => setStatusModalsDetails(null)}
        statusObj={statusModalsDetails}
        transcriptsId={statusModalsDetails?.id}
      />

      <div className="paginator">
        <CpPagination
          key="all"
          onChange={(page) => {
            actions.setPage(page);
          }}
          initialPage={page}
          lastPage={lastPage}
          pagesToDisplay={10}
        />
      </div>

      <TranscriptRequestSettings
        show={!!settingsModalIds.clientId}
        onClose={() => setSettingsModalIds({})}
        onSaveCb={(savedSettings) => actions.updateRowSettings(savedSettings)}
        onDeleteCb={(clientId, undo) => {
          if (undo) {
            actions.refetch();
          } else {
            actions.removeTranscriptsRow(clientId);
          }
        }}
        clientId={settingsModalIds?.clientId}
        contactId={settingsModalIds?.contactId}
        scheduleTabActive={false}
      />
    </Scoped>
  );
};

const css = k`
  .paginator {
    margin-bottom: 1.6rem;
    margin-top: 0.8rem;
    display: flex;
    justify-content: center;
  }
`;
