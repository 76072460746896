import React from "react";
import { DateTime } from "luxon";
import { useCss, k, always, maybe } from "kremling";
import { isEmpty } from "lodash";
import { CpButton, CpDropdown, CpToggle } from "canopy-styleguide!sofe";
import { transcriptStatusTypes } from "../common/transcripts-status.helper";

export default function VersionSelector({
  selectedVersion = {},
  setSelectedVersion,
  versions,
  comparedVersion = {},
  setComparedVersion,
  showCompareVersionSelector = false,
  setComparingVersions,
  comparingVersions = false,
}) {
  const scoped = useCss(css);

  const getVersionSelectorString = (version) => {
    if (isEmpty(version)) {
      return "Select a version";
    }

    return version.created_at
      ? DateTime.fromISO(version.created_at).toLocaleString(
          DateTime.DATETIME_MED
        )
      : "";
  };

  return (
    <div {...scoped}>
      <div>
        <div>{showCompareVersionSelector ? "Viewing version" : "Version"}</div>
        <div className="cp-flex">
          <CpDropdown
            contentWidth={220}
            contentHeight={185}
            position="bottom-start"
            renderTrigger={({ toggle }) => (
              <CpButton btnType="secondary" onClick={toggle} dropdown>
                {getVersionSelectorString(selectedVersion)}
              </CpButton>
            )}
            renderContent={() => (
              <div className="cp-select-list">
                {versions.map((v) => {
                  return (
                    <button
                      key={v.id}
                      onClick={() => setSelectedVersion(v)}
                      className="cp-flex-spread"
                    >
                      <span
                        className={maybe(
                          "cp-color-app-inactive-text",
                          showCompareVersionSelector &&
                            comparingVersions &&
                            !v.version
                        )}
                      >
                        {getVersionSelectorString(v)}
                      </span>
                      {v.pull_method === "SECURE_MAILBOX" && (
                        <div
                          style={{
                            color: "var(--cp-color-app-secondary-text)",
                            fontSize: 11,
                          }}
                        >
                          SOR
                        </div>
                      )}
                    </button>
                  );
                })}
              </div>
            )}
          />
          {showCompareVersionSelector && <div className="color-box" />}
        </div>
      </div>
      {showCompareVersionSelector && (
        <>
          <div className="cps-wt-semibold cp-mt-16 cp-mb-8 cp-flex-spread">
            Compare versions{" "}
            <CpToggle
              checked={comparingVersions}
              onChange={setComparingVersions}
              disabled={!selectedVersion.version}
            />
          </div>
          <div>
            <div>Comparing with</div>
            <div className="cp-flex">
              <div style={{ width: "19rem" }}>
                <CpDropdown
                  contentWidth={220}
                  contentHeight={185}
                  position="bottom-start"
                  renderTrigger={({ toggle }) => (
                    <CpButton
                      btnType="secondary"
                      onClick={toggle}
                      dropdown
                      disabled={!comparingVersions}
                      style={{ maxWidth: "18.6rem" }}
                    >
                      {getVersionSelectorString(comparedVersion)}
                    </CpButton>
                  )}
                  renderContent={() => (
                    <div className="cp-select-list" {...scoped}>
                      {versions
                        .filter((v) => !!v.version)
                        .map((v) => {
                          const isDisabled =
                            selectedVersion.id === v.id ||
                            v.category ===
                              transcriptStatusTypes.PARTIAL_SUCCESS;
                          return (
                            <button
                              key={v.id}
                              disabled={isDisabled}
                              onClick={() => setComparedVersion(v)}
                              className={always("cp-flex-spread").m(
                                "item-disabled",
                                isDisabled
                              )}
                            >
                              {getVersionSelectorString(v)}
                              {v.pull_method === "SECURE_MAILBOX" && (
                                <div
                                  style={{
                                    color: "var(--cp-color-app-secondary-text)",
                                    fontSize: 11,
                                  }}
                                >
                                  SOR
                                </div>
                              )}
                            </button>
                          );
                        })}
                    </div>
                  )}
                />
              </div>
              <div className="color-box diff-color" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const css = k`
  .color-box {
    width: 24px;
    height: 32px;
    background: #3366BB;
    border-radius: 3px;
    margin-left: 4px;
  }

  .diff-color {
    background: #2CB9C7;
  }

  .item-disabled {
    color: var(--cp-color-app-inactive-text);
    cursor: default;
  }

  .item-disabled:hover {
    background-color: var(--cp-color-card-bg);
  }
`;
