import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { Scoped, k, a } from "kremling";
import { get } from "lodash";
import {
  CprDateRangePicker,
  CpIcon,
  CpButton,
  CpInput,
} from "canopy-styleguide!sofe";
import { dateRangeTypes, defaultDateFilterRanges } from "./filter-menu.helper";

const FilterDatesMenu = ({
  updateFilterParams,
  persistingFilter,
  persistingSort,
  close,
  column,
  isScheduledList,
}) => {
  const [startDateText, setStartDateText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [endDateText, setEndDateText] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateInvalid, setEndDateInvalid] = useState(false);
  const [sortDirection, setSortDirection] = useState(null);
  const [dateFilterType, setDateFilterType] = useState("");
  const { filterable } = column;

  useEffect(() => {
    const startDate = convertToDefaultFormat(
      get(persistingFilter, "after_date", "")
    );
    const endDate = convertToDefaultFormat(
      get(persistingFilter, "before_date", "")
    );
    const pastDue = get(persistingFilter, "past_due", "");

    setDateFilterType(getDefaultFilterType(startDate, endDate, pastDue));
    setStartDateText(startDate);
    setEndDateText(endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  }, [persistingFilter]);

  useEffect(() => {
    setSortDirection(persistingSort);
  }, [persistingSort]);

  const convertToDefaultFormat = (date) => {
    // We need to format them to 'MM/DD/YYYY' for the date component
    if (date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
    }
  };

  const handleRangeChange = (e) => {
    const { startDate = "", endDate = "" } = e.detail;
    let formattedStartDate = startDate;
    let formattedEndDate = endDate;
    if (startDate) {
      // from double digit to single 08 -> 8
      formattedStartDate = DateTime.fromFormat(
        startDate,
        "MM/dd/yyyy"
      ).toLocaleString(DateTime.DATE_SHORT);
    }

    if (endDate) {
      // from double digit to single 08 -> 8
      formattedEndDate = DateTime.fromFormat(
        endDate,
        "MM/dd/yyyy"
      ).toLocaleString(DateTime.DATE_SHORT);
    }

    if (
      formattedStartDate === startDateText &&
      formattedEndDate === endDateText
    )
      return;

    const selectedDateFilter = getDefaultFilterType(
      formattedStartDate,
      formattedEndDate
    );
    updateDates(selectedDateFilter, formattedStartDate, formattedEndDate);
  };

  const getDefaultFilterType = (startDate, endDate, pastDue) => {
    if (pastDue) return dateRangeTypes.pastDue;
    if (!startDate && !endDate) return "";
    let selectedDateFilter = dateRangeTypes.custom;
    // goes through default filters to see if the dates match any of them
    defaultDateFilterRanges(isScheduledList).forEach((range) => {
      if (
        range.filter.startDate === startDate &&
        range.filter.endDate === endDate
      ) {
        selectedDateFilter = range.type;
      }
    });

    return selectedDateFilter;
  };

  const startDateOnBlur = (e) => {
    const value = e.target.value;
    if (!value || DateTime.fromJSDate(new Date(value)).isValid) {
      updateDates(dateRangeTypes.custom, value, endDate);
    } else {
      setStartDateInvalid("Invalid date");
    }
  };

  const endDateOnBlur = (e) => {
    const value = e.target.value;
    if (!value || DateTime.fromJSDate(new Date(value)).isValid) {
      updateDates(dateRangeTypes.custom, startDate, value);
    } else {
      setEndDateInvalid("Invalid date");
    }
  };

  const updateDates = (
    selectedDateFilter = "",
    startDate = "",
    endDate = ""
  ) => {
    setDateFilterType(selectedDateFilter);
    setStartDateText(startDate);
    setStartDate(startDate);
    setStartDateInvalid(false);
    setEndDateText(endDate);
    setEndDate(endDate);
    setEndDateInvalid(false);
  };

  const changeSortDirection = (sort) => {
    if (sort === sortDirection) {
      setSortDirection(null);
    } else {
      setSortDirection(sort);
    }
  };

  const submitFilter = () => {
    const filterParams = {};
    let persistSort = false;
    if (isScheduledList && dateFilterType === dateRangeTypes.pastDue) {
      filterParams.past_due = true;
    } else {
      if (startDate)
        filterParams.after_date = DateTime.fromFormat(startDate, "M/d/yyyy")
          .set({ hour: 0, minute: 0, second: 0 })
          .toUTC()
          .toISO();
      if (endDate)
        filterParams.before_date = DateTime.fromFormat(endDate, "M/d/yyyy")
          .set({ hour: 23, minute: 59, second: 59 })
          .toUTC()
          .toISO();
    }

    if (!sortDirection && !persistingSort) persistSort = true;

    updateFilterParams(column, sortDirection, filterParams, persistSort);
    close();
  };

  return (
    <Scoped css={css}>
      <div
        className="date-filter-menu"
        style={{ width: filterable ? "500px" : "300px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="cp-flex-spread">
          <div className="dfm-left">
            <div className="cp-pv-8 cp-ph-16">
              <div
                className={a("sort-option").m(
                  "cp-color-app-primary",
                  sortDirection === "+"
                )}
                onClick={() => changeSortDirection("+")}
              >
                <div>
                  Sort{" "}
                  {isScheduledList ? "Current - Future" : "Oldest - Newest"}
                </div>
                {sortDirection === "+" && (
                  <div style={{ marginTop: "-4px" }}>
                    <CpIcon name="checkmark-small" />
                  </div>
                )}
              </div>
              <div
                className={a("sort-option").m(
                  "cp-color-app-primary",
                  sortDirection === "-"
                )}
                onClick={() => changeSortDirection("-")}
              >
                <div>
                  Sort{" "}
                  {isScheduledList ? "Future - Current" : "Newest - Oldest"}
                </div>
                {sortDirection === "-" && (
                  <div style={{ marginTop: "-4px" }}>
                    <CpIcon name="checkmark-small" />
                  </div>
                )}
              </div>
            </div>
            {filterable && (
              <>
                <div className="cp-ph-16">
                  <hr className="cp-mv-0" />
                </div>
                <div className="cp-pv-8 cp-ph-16">
                  {defaultDateFilterRanges(isScheduledList).map(
                    (filterRange) => {
                      let typeSelected = dateFilterType === filterRange.type;

                      return (
                        <div
                          key={filterRange.label}
                          className={a("cp-pv-8 cp-flex-spread").m(
                            "cp-color-app-primary",
                            typeSelected
                          )}
                          onClick={() =>
                            updateDates(
                              filterRange.type,
                              filterRange.filter.startDate,
                              filterRange.filter.endDate
                            )
                          }
                        >
                          {filterRange.label}
                          {typeSelected && (
                            <div style={{ marginTop: "-4px" }}>
                              <CpIcon name="checkmark-small" />
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
          </div>
          {filterable && (
            <div className="cp-pr-16">
              <CprDateRangePicker
                startDate={startDate}
                endDate={endDate}
                events={{
                  rangechange: handleRangeChange,
                }}
              />
              <div className="cp-mb-16 cp-ml-16">
                <CpInput
                  className="cp-mb-8"
                  label="Start Date"
                  value={startDateText || ""}
                  onChange={setStartDateText}
                  onBlur={startDateOnBlur}
                  placeholder="MM/DD/YYYY"
                  error={startDateInvalid}
                />
                <CpInput
                  label="End Date"
                  value={endDateText || ""}
                  onChange={setEndDateText}
                  onBlur={endDateOnBlur}
                  placeholder="MM/DD/YYYY"
                  disabled={!startDateText || startDateInvalid}
                  error={endDateInvalid}
                />
              </div>
            </div>
          )}
        </div>
        <div className="apply-filter">
          <div>
            <CpButton className="cp-mr-8" onClick={submitFilter}>
              Apply
            </CpButton>
            <CpButton onClick={close} btnType="flat">
              Cancel
            </CpButton>
          </div>
          <CpButton
            onClick={() => {
              updateDates();
              changeSortDirection(null);
            }}
            btnType="flat"
          >
            Clear
          </CpButton>
        </div>
      </div>
    </Scoped>
  );
};

export default FilterDatesMenu;

const css = k`
  .date-filter-menu {
    font-weight: normal;
    position: absolute;
    color: var(--cp-color-app-primary-text);
    top: 51px;
    left: 0;
    background: var(--cp-color-app-primary-dark-text);
    z-index: 1000;
    border-radius: 5px;
    border: none;
    box-shadow: 0 4px 8px 0 var(--cp-color-menu-hover-bg), 0 2px 6px 0 var(--cp-color-nav-label);
  }

  .dfm-left {
    width: 100%;
    padding-right: 16px;
  }
  .sort-option {
    display: flex;
    padding-bottom: 8px;
    cursor: pointer;
    justify-content: space-between;
  }

  .apply-filter {
    border-top: 1px solid var(--cp-color-card-border);
    background: var(--cp-color-card-bg);
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    font-size: 13px;
    align-items: center;
  }
`;
