import React, { useContext } from "react";
import { isEmpty } from "lodash";
import { CpTable, CpEmptyState } from "canopy-styleguide!sofe";
import { TranscriptsContext } from "../use-transcripts-data.hook";

export const TranscriptsTable = () => {
  const { loading, schema, data, selection, filterControl } =
    useContext(TranscriptsContext);

  const getEmptyState = () => {
    if (data.length > 0) return null;

    const subText = isEmpty(filterControl.filters)
      ? "Use your IRS e-services account and your power of attorney to get started."
      : "There are no requests that meet your filter selections. Please refine your filters or request a new transcript.";

    return () => (
      <CpEmptyState
        img="es_transcripts_list"
        text="No Transcript Requests"
        subText={subText}
        className="cp-pv-40"
      />
    );
  };

  return (
    <CpTable
      schema={schema}
      data={data}
      fullWidth
      selection={selection}
      filterControl={filterControl}
      showLoader={loading}
      renderEmptyState={getEmptyState()}
    />
  );
};
