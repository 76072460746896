import React, { useEffect, useRef, useState } from "react";
import { cloneDeep, findIndex, isEmpty } from "lodash";
import { filter as rxFilter, pluck } from "rxjs/operators";
import { onPusher } from "fetcher!sofe";
import { transcriptStatusTypes } from "src/common/transcripts-status.helper";
import {
  getIrsOrganizations,
  getScheduledList,
} from "src/resources/transcripts.resource";
import { handleError } from "src/error";

export const ScheduledTranscriptsContext = React.createContext(null);

export const useScheduledTranscriptsData = (activeTab) => {
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [triggerFetchTranscripts, setTriggerFetchTranscripts] = useState();
  const [scheduledItems, setScheduledItems] = useState([]);
  const [curPage, setCurPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [scheduleSort, setScheduleSort] = useState({
    sortColumn: null,
    sortDirection: null,
  });
  const [scheduleFilterParams, setScheduleFilterParams] = useState({
    client_id: [],
    cadence: {},
    next_run_date: {},
    organization: [],
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  const scheduledListSub = useRef(null);

  useEffect(() => {
    const organizationsSub = getIrsOrganizations().subscribe(
      (organizations) => {
        const validOrgs = organizations.filter((org) => !org.deleted_at);
        setOrganizations(validOrgs);
      },
      handleError
    );
    return () => {
      scheduledListSub.current?.unsubscribe();
      organizationsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setScheduleLoading(true);
    setTriggerFetchTranscripts({});
  }, [activeTab, curPage, scheduleSort, scheduleFilterParams]);

  useEffect(() => {
    const storedScheduleFilterParams = localStorage.getItem(
      "transcripts-schedule-filters"
    );

    if (storedScheduleFilterParams) {
      const { scheduleFilterParams, scheduleSort } = JSON.parse(
        storedScheduleFilterParams
      );
      setScheduleSort(scheduleSort);
      setScheduleFilterParams(scheduleFilterParams);
    }

    const transcriptsPusherSub = onPusher("transcripts")
      .pipe(
        rxFilter((msg) => msg.data_needed === "status_change"),
        pluck("details")
      )
      .subscribe((details) => {
        if (details.category === transcriptStatusTypes.SUCCESS) {
          setTriggerFetchTranscripts({});
        }
      }, handleError);

    return () => {
      transcriptsPusherSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!triggerFetchTranscripts || activeTab !== "SCHEDULED") return;
    const { isNewClient } = triggerFetchTranscripts;
    const limit = 50;
    const offset = limit * curPage;
    const clonedFilterParams = cloneDeep(scheduleFilterParams);
    const emptyFilterProps = Object.keys(clonedFilterParams).filter(
      (colType) => {
        return isEmpty(clonedFilterParams[colType]);
      }
    );

    emptyFilterProps.forEach((colType) => delete clonedFilterParams[colType]);

    scheduledListSub.current = getScheduledList(limit, offset, {
      tableSort: scheduleSort,
      filterParams: clonedFilterParams,
      isNewClient,
    }).subscribe(
      (res) => {
        setAllSelected(false);
        setScheduledItems(res.schedules);
        setLastPage(Math.floor(res.total / limit));
        setScheduleLoading(false);
        setSelectedItems([]);
      },
      (err) => {
        setScheduleLoading(false);
        handleError(err);
      }
    );
  }, [
    triggerFetchTranscripts,
    activeTab,
    curPage,
    scheduleSort,
    scheduleFilterParams,
  ]);

  const updateScheduleFilters = (
    column,
    sortDirection,
    filterSelections,
    persistSort
  ) => {
    const { columnType, sortName } = column;

    let newSort;
    let newFilterParams = scheduleFilterParams;

    const sortUpdated =
      sortName !== scheduleSort?.sortColumn ||
      sortDirection !== scheduleSort.sortDirection;
    const filterUpdated = scheduleFilterParams[columnType] !== filterSelections;

    if ((!sortUpdated || persistSort) && !filterUpdated) {
      return;
    } else if (persistSort) {
      newSort = scheduleSort;
    } else if (sortUpdated && !sortDirection) {
      newSort = { sortColumn: null, sortDirection: null };
    } else if (sortUpdated) {
      newSort = { sortColumn: sortName, sortDirection };
    }

    if (filterUpdated) {
      newFilterParams = {
        ...scheduleFilterParams,
        [columnType]: filterSelections,
      };
    }

    localStorage.setItem(
      "transcripts-schedule-filters",
      JSON.stringify({
        scheduleSort: newSort,
        scheduleFilterParams: newFilterParams,
      })
    );

    setCurPage(0);
    setScheduleSort(newSort);
    setScheduleFilterParams(newFilterParams);
  };

  const selectItem = (item) => {
    setSelectedItems([...selectedItems, item.irs_token_id]);
  };

  const deselectItem = (item) => {
    const newSelectedItems = selectedItems.filter(
      (id) => id !== item.irs_token_id
    );
    setAllSelected(false);
    setSelectedItems(newSelectedItems);
  };

  const selectAllItems = () => {
    const newSelectedItems = scheduledItems.map((item) => item.irs_token_id);
    setAllSelected(true);
    setSelectedItems(newSelectedItems);
  };

  const deselectAllItems = () => {
    setAllSelected(false);
    setSelectedItems([]);
  };

  const removeTranscriptsRow = (clientId) => {
    // remove transcript row without fetching all transcripts again
    const scheduledList = scheduledItems.filter(
      (transcript) => transcript.client_id !== clientId
    );
    setScheduledItems(scheduledList);
  };

  const updateRowSettings = (settings) => {
    const scheduledList = cloneDeep(scheduledItems);

    const scheduledIndex = findIndex(
      scheduledList,
      (transcript) => transcript.client_id === settings.client_id
    );

    if (scheduledIndex >= 0) {
      if (!settings.cadence && scheduledList[scheduledIndex].cadence) {
        scheduledList.splice(scheduledIndex, 1);
      } else {
        // update transcript settings for row without fetching all transcripts schedules again
        scheduledList[scheduledIndex].cadence = settings.cadence;
        scheduledList[scheduledIndex].next_run_at = settings.next_run_at;
        scheduledList[scheduledIndex].has_settings = true;
        scheduledList[scheduledIndex].irs_token_id = settings.irs_token_id;
        scheduledList[scheduledIndex].dba = organizations.find(
          (org) => org.id === settings.irs_token_id
        )?.dba;
      }
    }

    setScheduledItems(scheduledList);
  };

  const actions = {
    setScheduledItems,
    setCurPage,
    setTriggerFetchTranscripts,
    updateScheduleFilters,
    removeTranscriptsRow,
    updateRowSettings,
    selectItem,
    deselectItem,
    selectAllItems,
    deselectAllItems,
  };

  return {
    scheduleLoading,
    scheduledItems,
    curPage,
    lastPage,
    scheduleSort,
    scheduleFilterParams,
    selectedItems,
    allSelected,
    actions,
  };
};
