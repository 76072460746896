import React from "react";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { maybe } from "kremling";
import { CpButton, CpTooltip, CpIcon } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { notifyAnalytics } from "src/resources/analytics.resource";
import { queueTranscriptBulkRepull } from "src/resources/transcripts.resource";
import {
  translateTranscriptStatus,
  transcriptStatusTypes,
} from "../common/transcripts-status.helper";
import { isExpiredDate } from "src/e-services/common/settings.helper";
import { handleError } from "src/error";

export const getContents = ({
  column,
  item,
  userHasTranscriptPullPerm,
  showSettingsModal,
  setStatusObj,
  setShowRequestDialog,
  transcriptsCount,
  activeOrgs = [],
  isScheduledList = false,
  isCrmHeirarchy = false,
}) => {
  switch (column) {
    case "client_id":
      return <div className="fake-link-text">{item["client_name"]}</div>;
    case "client_name":
      return <div className="fake-link-text">{item[column]}</div>;
    case "contact_id":
      return <div>{item["contact_name"] || "–"}</div>;
    case "status":
      return (
        <div style={{ marginLeft: "-8px" }}>
          {translateTranscriptStatus({
            status: item.status,
            id: item.transcript_id,
            setStatusObj,
            category: item.category,
          })}
        </div>
      );
    case "cadence":
      return <div>{capitalize(item.cadence)}</div>;
    case "next_run_cadence":
      return item?.next_run_at ? (
        <div>
          {DateTime.fromISO(item.next_run_at).toLocaleString(DateTime.DATE_MED)}
          <span className="cp-color-app-inactive-text">
            {" "}
            | {capitalize(item["cadence"])}
          </span>
        </div>
      ) : (
        "–"
      );
    case "poa_expiration_at":
      return item[column] ? (
        <div
          className={maybe(
            "cp-color-app-warning-text",
            isExpiredDate(item[column])
          )}
        >
          {DateTime.fromISO(item[column]).toLocaleString(DateTime.DATE_MED)}
        </div>
      ) : (
        "–"
      );
    case "next_run_at":
      return item[column] ? (
        DateTime.utc().toISO() > item[column] ? (
          <div
            className="cp-flex-center"
            style={{
              marginLeft: "-2.4rem",
              fontWeight: 600,
            }}
          >
            <CpIcon
              className="cp-mr-4"
              name="shape-circle-closed"
              fill="var(--cp-color-notification-badge-bg)"
              style={{ height: "1.6rem" }}
            />
            {DateTime.fromISO(item[column]).toLocaleString(DateTime.DATE_MED)}
          </div>
        ) : (
          <div className="cp-ml-4">
            {DateTime.fromISO(item[column]).toLocaleString(DateTime.DATE_MED)}
          </div>
        )
      ) : (
        <div className="cp-ml-4">–</div>
      );
    case "organization":
      return item.dba ? (
        <div style={{ marginLeft: "-0.8rem" }}>
          <CpIcon
            className="cp-mr-4"
            name={
              activeOrgs?.find((org) => item.irs_token_id === org.id)
                ? "shape-circle-closed"
                : "shape-circle-open-small"
            }
            fill={
              activeOrgs?.find((org) => item.irs_token_id === org.id)
                ? "var(--cp-color-app-primary)"
                : "var(--cp-color-app-icon)"
            }
          />
          {item.dba}
        </div>
      ) : (
        "-"
      );
    case "tools":
      return transcriptListTools({
        item,
        userHasTranscriptPullPerm,
        showSettingsModal,
        setShowRequestDialog,
        transcriptsCount,
        activeOrgs,
        isScheduledList,
        isCrmHeirarchy,
      });
    default:
      if (dateTimeTypes.includes(column)) {
        const date = item[column];
        return date
          ? DateTime.fromISO(`${date}+00:00`).toLocaleString(
              DateTime.DATETIME_MED
            )
          : "–";
      }
      return item[column];
  }
};

const dateTimeTypes = ["last_pull_date", "last_successful_pull_date"];

const transcriptListTools = ({
  item,
  userHasTranscriptPullPerm,
  showSettingsModal,
  setShowRequestDialog,
  transcriptsCount,
  activeOrgs,
  isScheduledList,
  isCrmHeirarchy,
}) => {
  const pendingStatus = item?.category === transcriptStatusTypes.PENDING;
  const orgIsActive = activeOrgs.length
    ? !!activeOrgs.find((org) => org.id === item.irs_token_id)
    : false;

  return (
    <div className="tools-column" onClick={(e) => e.preventDefault()}>
      {!isScheduledList && userHasTranscriptPullPerm && (
        <CpTooltip text="Request again">
          <span>
            <CpButton
              icon="af-refresh"
              aria-label="Request transcripts again"
              onClick={() =>
                queueUpTranscriptPull({
                  clientId: item.client_id,
                  contactId: item.contact_id,
                  missingSettings:
                    !item.has_settings || item.add_missing_details,
                  setShowRequestDialog,
                  orgIsActive,
                  isCrmHeirarchy,
                })
              }
              disabled={
                pendingStatus ||
                transcriptsCount?.successful_count >= transcriptsCount?.limit
              }
            />
          </span>
        </CpTooltip>
      )}
      <CpButton
        icon="misc-gear"
        aria-label="Transcripts request settings"
        onClick={() => showSettingsModal(item.client_id, item.contact_id)}
      />
    </div>
  );
};

const queueUpTranscriptPull = ({
  clientId,
  contactId,
  missingSettings,
  setShowRequestDialog,
  orgIsActive,
  isCrmHeirarchy,
}) => {
  if (missingSettings || !orgIsActive) {
    setShowRequestDialog(true, clientId);
  } else {
    const body = isCrmHeirarchy
      ? {
          client_and_contact_map: [
            { client_id: clientId, contact_id: contactId },
          ],
        }
      : { client_ids: [clientId] };
    return queueTranscriptBulkRepull(body).subscribe(() => {
      notifyAnalytics("transcripts.requested_again");
      successToast("Your transcripts request has been submitted.");
    }, handleError);
  }
};
