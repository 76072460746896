import React from "react";
import { capitalize } from "lodash";
import { DateTime } from "luxon";

export const ScheduleCell = ({ value, resource }) => {
  return value ? (
    <div>
      {DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)}
      <span className="cp-color-app-inactive-text">
        {` | ${capitalize(resource.cadence)}`}
      </span>
    </div>
  ) : (
    ""
  );
};
