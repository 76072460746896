import React from "react";
import { DateTime } from "luxon";
import { isExpiredDate } from "src/e-services/common/settings.helper";

export const POAExpirationCell = ({ value }) => {
  return (
    <div className={isExpiredDate(value) ? "cp-color-app-warning-text" : ""}>
      {DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)}
    </div>
  );
};
