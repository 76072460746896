import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { a } from "kremling";
import {
  CpButton,
  CpDropdown,
  CpIcon,
  CpTooltip,
  CpModal,
} from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { notifyAnalytics } from "src/resources/analytics.resource";
import { getIrsAuthUrl } from "../resources/transcripts.resource";
import { handleError } from "src/error";

export default function IrsStatus({
  organizations,
  fetchOrganizations,
  transcriptsLimitReached,
}) {
  const [showAddOrgInstructions, setShowAddOrgInstructions] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasTranscriptsPull = useHasAccess("transcripts_pull");
  const activeOrgs = useMemo(
    () => getActiveOrgs(organizations),
    [organizations]
  );

  return hasTranscriptsPull ? (
    activeOrgs.length ? (
      <div className="cp-flex-column cp-mh-24">
        <div className="cp-flex-center">
          <CpIcon
            className="cp-mr-4"
            name="checkmark-circle-filled-small"
            fill="var(--cp-color-app-primary)"
          />
          <strong className="cp-color-app-primary cp-body">
            Signed in to the IRS
          </strong>
          <CpDropdown
            renderTrigger={({ toggle }) => (
              <CpButton
                icon="caret-small-down"
                onClick={toggle}
                aria-label="IRS sign in options"
                disabled={transcriptsLimitReached}
              />
            )}
            renderContent={() => (
              <div className="cp-select-list">
                <button
                  onClick={() => {
                    notifyAnalytics("transcripts.add_organization_click");
                    setShowAddOrgInstructions(true);
                  }}
                >
                  Change organization
                </button>
              </div>
            )}
            position="bottom-end"
          />
        </div>
        <div>
          <CpTooltip
            text={activeOrgs.reduce(
              (acc, org) => `${acc}${acc ? ", " : ""}${org.dba}`,
              ""
            )}
          >
            <div
              className={a("cp-flex cp-color-app-inactive-text cp-caption").t(
                "cp-ml-28",
                "cp-ml-8",
                activeOrgs.length <= 1
              )}
              style={{ width: "18rem", whiteSpace: "nowrap" }}
            >
              <div className="cp-ellipsis">{activeOrgs[0].dba}&nbsp;</div>
              <div>
                {activeOrgs.length > 1
                  ? `and ${activeOrgs.length - 1} other${
                      activeOrgs.length > 2 ? "s" : ""
                    }`
                  : ""}
              </div>
            </div>
          </CpTooltip>
        </div>
        <CpModal
          show={showAddOrgInstructions}
          onClose={() => setShowAddOrgInstructions(false)}
          width={540}
        >
          <CpModal.Header title="Change organization" />
          <CpModal.Body>
            <div>
              <div className="cp-mb-16">
                <em className="cp-color-app-inactive-text">
                  Starting sessions with multiple organizations simultaneously
                  is currently not supported by the IRS through their ISP
                  authentication method.
                </em>
              </div>
              <strong>
                Follow the steps below to sign into a new organization:
              </strong>
              <ol style={{ marginLeft: "-2rem" }}>
                <li>
                  Wait for your current Canopy session to expire (1 hour from
                  sign on).
                </li>
                <li>
                  Visit the IRS TDS site and log out of your current
                  organization.
                </li>
                <li>
                  Return to Canopy and complete the sign in steps this time
                  using a different organization.
                </li>
              </ol>
            </div>
          </CpModal.Body>
          <CpModal.Footer>
            <CpButton
              btnType="primary"
              onClick={() => setShowAddOrgInstructions(false)}
            >
              Close
            </CpButton>
            <CpButton
              btnType="flat"
              className="cp-ml-8"
              onClick={() => setShowAddOrgInstructions(false)}
              id="canopy-intercom-launcher"
            >
              Contact support
            </CpButton>
          </CpModal.Footer>
        </CpModal>
      </div>
    ) : (
      <div className="cp-flex-center">
        <em className="cp-mr-16 cp-color-app-inactive-text">
          1-hour sign in sessions
        </em>
        <CpButton
          className="cp-mr-16"
          btnType="secondary"
          onClick={() => routeToIrs(fetchOrganizations, setLoading)}
          showLoader={loading}
          disabled={transcriptsLimitReached}
        >
          Sign in to the IRS
        </CpButton>
      </div>
    )
  ) : null;
}

// IRS Organization token status helpers
export const routeToIrs = (fetchOrganizations, setLoading) => {
  getIrsAuthUrl()
    .then((authUrl) => {
      let screenWidth =
        window.screenWidth != undefined ? window.screenWidth : screen.width;

      const irsLoginWindow = window.open(
        authUrl,
        "Login to IRS",
        `top=${window.screenTop}, left=${screenWidth}, width=800, height=800`
      );

      setLoading?.(true);

      // when the window closes check the current irs connection status again
      const checkAuthWindow = () => {
        if (irsLoginWindow?.closed) {
          fetchOrganizations?.();
          setLoading?.(false);
        } else {
          setTimeout(checkAuthWindow, 2000);
        }
      };
      // don't check if the window is closed the first time for 5 seconds
      setTimeout(checkAuthWindow, 5000);
    })
    .catch(handleError);
};

export const getActiveOrgs = (organizations) =>
  organizations.filter(
    (org) => org.login_needed_at > DateTime.now().toUTC().toISO()
  );

export const isOrgActive = (org) =>
  org?.login_needed_at > DateTime.now().toUTC().toISO();
